var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{staticClass:"shrimp-talk-container",attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false,"title":((_vm.$t('Comn_update')) + " " + (_vm.$t('Comn_shrimptalk'))),"custom-class":"shrimptalk_action_dialog"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":_vm.handleCloseDialog,"open":_vm.handleOpenDialog}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"shrimpTalkForm",staticClass:"demo-shrimpTalkForm",attrs:{"size":"small","model":_vm.shrimpTalkForm}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$tc('Comn_pond_name', 0)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$tc('Comn_pond_name', 0),"prop":"pond","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$tc('Comn_pond_name', 0),"collapse-tags":"","value-key":"title","reserve-keyword":true,"multiple":"","filterable":"","size":"small"},on:{"change":_vm.handleChangePond},model:{value:(_vm.shrimpTalkForm.pond),callback:function ($$v) {_vm.$set(_vm.shrimpTalkForm, "pond", $$v)},expression:"shrimpTalkForm.pond"}},[_vm._l((_vm.pondsData),function(pond){return [(pond.shrimp_talks.length > 0)?_c('el-option',{key:pond._id,attrs:{"label":pond.title,"value":pond._id}}):_vm._e()]})],2)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('Comn_shrimp_talk_code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('Comn_shrimp_talk_code'),"prop":"shrimp_talk_id","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$t('Comn_shrimp_talk_code'),"collapse-tags":"","value-key":"code","reserve-keyword":true,"disabled":_vm.disable_field,"multiple":"","filterable":"","size":"small"},on:{"change":_vm.handleChangeShrimpTalk},model:{value:(_vm.shrimpTalkForm.shrimp_talk_id),callback:function ($$v) {_vm.$set(_vm.shrimpTalkForm, "shrimp_talk_id", $$v)},expression:"shrimpTalkForm.shrimp_talk_id"}},_vm._l((_vm.selectedPondSTs),function(st){return _c('el-option',{key:st._id,attrs:{"value":st,"label":_vm.upm__getValue(st)}})}),1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('PM_day_feed'),"rules":{
          required_if_not: _vm.$t('Comn_feed_level'),
          max_value: this.upm__feedUnits === 'lb' ? 441 : 400,
          min_value: this.upm__feedUnits === 'lb' ? 44 : 20
        },"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('PM_day_feed'),"prop":"feedlimit","error":errors[0]}},[_c('el-input',{attrs:{"disabled":_vm.disable_field,"size":"small","controls":false,"placeholder":_vm.$t('PM_day_feed')},on:{"input":_vm.handlefeedlimit},model:{value:(_vm.shrimpTalkForm.feed_limit),callback:function ($$v) {_vm.$set(_vm.shrimpTalkForm, "feed_limit", $$v)},expression:"shrimpTalkForm.feed_limit"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('Comn_feed_level'),"immediate":"","rules":{
          required_if_not: _vm.$t('PM_day_feed')
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('Comn_feed_level'),"prop":"feeding_level","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$t('Comn_feed_level'),"disabled":_vm.disableFeedingLevel || _vm.disable_field,"size":"small"},on:{"input":_vm.handlefeedlevel},model:{value:(_vm.shrimpTalkForm.feeding_level),callback:function ($$v) {_vm.$set(_vm.shrimpTalkForm, "feeding_level", $$v)},expression:"shrimpTalkForm.feeding_level"}},_vm._l((_vm.feedingLevelData),function(level){return _c('el-option',{key:level.value,attrs:{"label":_vm.$t(level.label),"value":level.value}})}),1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"errorwithouthighlight",class:{ errorCss: _vm.errorhighlight }},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" info ")]),_vm._v(" \n        "+_vm._s(_vm.$t("Atleast_feed_limit_feed_level_is_required"))+"\n      ")])],1)],1),_vm._v(" "),_c('layout-toolbar',{attrs:{"slot":"footer","justify":"center"},slot:"footer"},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading,"disabled":_vm.isReadOnly || _vm.disable_field},on:{"click":_vm.submitResourceDetails}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.handleCloseDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: ruleOption.js
Description: This file contains the model, functions of rules used for handling validation in the pondlogs customer site
*/
const validationType = {
  REQUIRED: "REQUIRED",
  MIN: "MIN",
  MAX: "MAX",
  MIN_EQUAL: "MIN_EQUAL",
  MAX_EQUAL: "MAX_EQUAL",
  LESS_THAN_OTHER_FIELD: "LESS_THAN_OTHER_FIELD",
  GREATER_THAN_OTHER_FIELD: "GREATER_THAN_OTHER_FIELD",
  LESS_THAN_EQUAL_OTHER_FIELD: "LESS_THAN_EQUAL_OTHER_FIELD",
  GREATER_THAN_EQUAL_OTHER_FIELD: "GREATER_THAN_EQUAL_OTHER_FIELD"
};

class RuleOption {
  constructor() {
    this.validation_type = "REQUIRED";
    this.field_lang_key = "NO_FIELD_LANG_KEY";
    this.item_title = "NO_ITEM_TITLE";
    this.item_type_lang_key = "NO_ITEM_VALUE";
    this.threshold_value = 0;
    this.other_field_lang_key = "NO_OTHER_FIELD_LANG_KEY";
  }

  static createBasicRule(
    validation_type,
    field_lang_key,
    item_type_lang_key,
    item_title,
    threshold_value
  ) {
    const rule = new RuleOption();
    rule.validation_type = validationType[validation_type];
    rule.field_lang_key = field_lang_key;
    rule.item_type_lang_key = item_type_lang_key;
    rule.item_title = item_title;
    rule.threshold_value = threshold_value;
    return rule;
  }

  static createRuleToCompareWithOtherField(
    validation_type,
    field_lang_key,
    other_field_lang_key,
    item_type_lang_key,
    item_title,
    other_field_value
  ) {
    const rule = new RuleOption();
    rule.validation_type = validationType[validation_type];
    rule.field_lang_key = field_lang_key;
    rule.other_field_lang_key = other_field_lang_key;
    rule.item_type_lang_key = item_type_lang_key;
    rule.item_title = item_title;
    rule.threshold_value = other_field_value;
    return rule;
  }
}

export default RuleOption;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"settings-feedblower",attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),_c('el-row',[_c('layout-toolbar',{staticStyle:{"margin":"10px 10px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"legend-row__item"},[_c('div',{class:"color-indicatorvalve1"},[_vm._v(" ")]),_vm._v(" "),_c('div',{staticClass:"legend-row__label"},[_vm._v("\n          "+_vm._s(_vm.$t("Comn_left_pondmother"))+"\n        ")])]),_vm._v(" "),_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"btnType":"save","size":"mini","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly,"loading":_vm.loading},on:{"click":_vm.submitUpdatedDetails}}),_vm._v(" "),_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"btnType":"cancel","size":"mini","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.initComponent}})],1)],1),_vm._v(" "),(_vm.Ponds.length > 0)?_c('el-row',[_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,attrs:{"uniqueId":"feed-blower-settings","merge":{ startColumnNumber: 1, endColumnNumber: 1 },"tableData":_vm.tableData,"total":_vm.total,"loading":_vm.loading,"elTableProps":_vm.tableProps,"action-column":false,"columns":_vm.tableColumns,"type":"white-header-table"},scopedSlots:_vm._u([{key:"pond_title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond_title","withoutHighlightStrings":row.data.pond_title}})]}},{key:"pond_mothers",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pm-contianer"},[_vm._l((_vm.getfbRowPMs(row.data)),function(pm,index){return _c('el-tag',{key:index,attrs:{"size":"mini","type":_vm.tagToValveName[pm.valve_name],"color":_vm.colorToValveName[pm.valve_name],"closable":""},on:{"close":function($event){return _vm.handleRemovePms(pm._id, row.data, pm.code || pm.title)}}},[_vm._v("\n            "+_vm._s(_vm.upm__getValue(pm))+"\n          ")])}),_vm._v(" "),(_vm.getfbRowPMs(row.data).length === 0)?_c('el-tag',{attrs:{"type":"success","size":"mini"}},[_vm._v("\n            "+_vm._s(_vm.$t("Comn_no_pondmothers"))+"\n          ")]):_vm._e(),_vm._v(" "),_c('er-button',{attrs:{"type":"text","btnType":"add","showIcon":true,"size":"mini"},on:{"click":function($event){return _vm.handleAddPms($event, row.data)}}})],2)]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"alias-name-container"},[_c('el-input',{attrs:{"placeholder":_vm.$t('enter_alias_name'),"size":"mini"},on:{"change":function($event){return _vm.handleFBChangeInTable($event, row.data)}},model:{value:(row.data.title),callback:function ($$v) {_vm.$set(row.data, "title", $$v)},expression:"row.data.title"}})],1)]}}],null,false,1963078093)}),_vm._v(" "),_c('er-dialog',{attrs:{"visible":_vm.showDialog,"width":"30%","close-on-click-modal":false,"title":_vm.$tc("Ponds_select_pond_mothers", 2),"custom-class":"feedblower_pm_action_dialog"},on:{"update:visible":function($event){_vm.showDialog=$event},"open":_vm.handleOpenDialog,"closed":_vm.handleCloseDialog}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"size":"small"}},[_c('ValidationProvider',{attrs:{"name":"selectedLeftPM","immediate":"","rules":"required_if_not:selectedRightPM"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.formStrings.left.label,"label-width":"120px","error":errors[0]}},[_c('er-select',{staticClass:"select-devices",attrs:{"value-key":"_id","collapse-tags":"","filterable":"","clearable":"","placeholder":_vm.formStrings.left.placeholder,"no-data-text":_vm.$t('Comn_no_pondmothers')},model:{value:(_vm.selectedLeftPM),callback:function ($$v) {_vm.selectedLeftPM=$$v},expression:"selectedLeftPM"}},_vm._l((_vm.pmsInPond),function(pm){return _c('el-option',{key:pm._id,attrs:{"label":pm.code,"value":pm,"disabled":_vm.disablePondMother(pm, 'LEFT')}})}),1)],1)]}}],null,false,3154130066)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"selectedRightPM","immediate":"","rules":"required_if_not:selectedLeftPM"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.formStrings.right.label,"label-width":"120px","error":errors[0]}},[_c('er-select',{staticClass:"select-devices",attrs:{"value-key":"_id","collapse-tags":"","filterable":"","clearable":"","placeholder":_vm.formStrings.right.placeholder,"no-data-text":_vm.$t('Comn_no_pondmothers')},model:{value:(_vm.selectedRightPM),callback:function ($$v) {_vm.selectedRightPM=$$v},expression:"selectedRightPM"}},_vm._l((_vm.pmsInPond),function(pm){return _c('el-option',{key:pm._id,attrs:{"label":pm.code,"value":pm,"disabled":_vm.disablePondMother(pm, 'RIGHT')}})}),1)],1)]}}],null,false,129667570)})],1)],1),_vm._v(" "),_c('layout-toolbar',{attrs:{"slot":"footer","justify":"center"},slot:"footer"},[_c('er-button',{attrs:{"size":"mini","btnType":"save","loading":_vm.buttonLoading,"showIcon":true,"showLabel":true},on:{"click":function($event){return _vm.handleAssignPmsToFeedBlower(
              $event,
              _vm.selectedLeftPM,
              _vm.selectedRightPM
            )}}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true},on:{"click":_vm.handleCloseDialog}})],1)],1)],1):_c('el-row',[_vm._v(_vm._s(_vm.$t("Comn_no_data_connection")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
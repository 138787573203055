<template>
  <div class="main-container" v-loading="loading">
    <!-- <div v-if="getReleaseListComputed.length < 0">No data</div> -->
    <el-row class="release-notes-container">
      <div v-if="getReleaseListComputed.length > 0">
        <el-collapse
          v-model="getActiveRelease"
          v-loading="loading"
          accordion
          class="release-notes-collapse"
          @change="handleCollapseItemClick"
        >
          <template
            v-for="release in shouldSearchFeature === true
              ? filterdListByFeatTitle
              : releaseList"
          >
            <el-collapse-item
              :key="release._id"
              :name="release._id"
              class="colapse-item-custom-main"
            >
              <template slot="title">
                <el-row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  class="collapse-header__row"
                  :key="release._id"
                >
                  <el-col :span="5" class="collapse-header__row-item">
                    <p class="row-item__value truncate">
                      {{ getReleaseDate(release.created_at) }}
                    </p>
                  </el-col>
                  <el-col :span="14" class="collapse-header__row-item">
                  </el-col>
                  <el-col :span="5" class="collapse-header__row-item">
                    <el-row
                      style="width: 100%;"
                      type="flex"
                      justify="space-between"
                    >
                      <el-col class="display-icon-count" :span="8">
                        <div>
                          <img
                            src="@/assets/help/New Feature.svg"
                            style="width: 13px; height: 13px;"
                          />
                        </div>
                        <div>
                          <span class="count">{{
                            release.features.length
                          }}</span>
                        </div>
                      </el-col>
                      <el-col class="display-icon-count" :span="8">
                        <div>
                          <img
                            src="@/assets/help/Enhancement.svg"
                            style="width: 13px; height: 13px;"
                          />
                        </div>
                        <div>
                          <span class="count">
                            {{ release.enhancements.length }}</span
                          >
                        </div>
                      </el-col>
                      <el-col class="display-icon-count" :span="8">
                        <div>
                          <img
                            src="@/assets/help/Bug.svg"
                            style="width: 13px; height: 13px;"
                          />
                        </div>
                        <div>
                          <span class="count"> {{ release.bugs.length }}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </template>
              <template v-if="shouldReleaseContentShow">
                <release-notes-item-vue
                  :release="release"
                ></release-notes-item-vue>
              </template>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
      <div v-else class="no-data">
        {{ $t("Comn_no_data") }}
      </div>
    </el-row>
    <el-row class="release-notes-legend-container">
      <div class="release-notes-legend">
        <div class="release-notes-legend__feature">
          <div class="image">
            <img
              src="@/assets/help/New Feature.svg"
              style="width: 13px; height: 13px;"
            />
          </div>
          <div class="title">{{ $t("Comn_new_feature") }}</div>
        </div>
        <div class="release-notes-legend__enhancement">
          <div class="image">
            <img
              src="@/assets/help/Enhancement.svg"
              style="width: 13px; height: 13px;"
            />
          </div>
          <div class="title">{{ $t("Comn_enhancement") }}</div>
        </div>
        <div class="release-notes-legend__bug">
          <div class="image">
            <img
              src="@/assets/help/Bug.svg"
              style="width: 13px; height: 13px;"
            />
          </div>
          <div class="title">{{ $t("Comn_bug_fix") }}</div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import releaseNotesItemVue from "./releaseNotesItem.vue";
import { mapGetters, mapActions } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import eventBus from "@/components/shared/eventBus";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    userPreferenceMixin,
    datesHandlerMixin,
    redirectsMixin
  ],
  components: {
    releaseNotesItemVue
  },
  data: function() {
    return {
      shouldReleaseContentShow: true,
      releaseListOld: [
        {
          status: "RELEASED",
          version_number: "2.1.43",
          _id: "62eb61f175268051ec95cd39",
          created_at: "2022-08-05T10:16:55Z",
          features: [
            {
              key: "feature",
              _id: "62eb61f175268051ec95cd39a",
              title: "Feature 1",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            },
            {
              key: "feature_11",
              _id: "62eb61f175268051ec95cd39b",
              title: "Feature 2",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            }
          ],
          enhancements: [
            {
              key: "enhancement",
              _id: "62eb61f175268051ec95cd39c",
              title: "Enhancement 1",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            },
            {
              key: "enhancement_11",
              _id: "62eb61f175268051ec95cd39d",
              title: "Enhancement 2",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            }
          ],
          bugs: [
            {
              key: "bug",
              _id: "62eb61f175268051ec95cd39e",
              title: "Bug 1",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            },
            {
              key: "bug_11",
              _id: "62eb61f175268051ec95cd39f",
              title: "Bug 2",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            }
          ]
        },
        {
          status: "RELEASED",
          version_number: "2.1.43",
          _id: "62eb61f175268051ec95cd40",
          created_at: "2022-08-04T10:16:55Z",
          features: [
            {
              key: "feature",
              _id: "62eb61f175268051ec95cd39a",
              title: "Feature 1",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            },
            {
              key: "feature_11",
              _id: "62eb61f175268051ec95cd39b",
              title: "Feature 2",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            }
          ],
          enhancements: [
            {
              key: "enhancement",
              _id: "62eb61f175268051ec95cd39c",
              title: "Enhancement 1",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            },
            {
              key: "enhancement_11",
              _id: "62eb61f175268051ec95cd39d",
              title: "Enhancement 2",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            }
          ],
          bugs: [
            {
              key: "bug",
              _id: "62eb61f175268051ec95cd39e",
              title: "Bug 1",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            },
            {
              key: "bug_11",
              _id: "62eb61f175268051ec95cd39f",
              title: "Bug 2",
              content:
                '<ol><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span><img src="https://images.unsplash.com/photo-1659461258646-1544f02ad35b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li><li><strong style="color: rgb(0, 0, 0);">Lorem Ipsum</strong><span style="color: rgb(0, 0, 0);">&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</span></li></ol>'
            }
          ]
        }
      ],
      releaseList: [],
      activeRelease: "",
      filterdListByFeatTitle: [],
      loading: false,
      params: {
        // limit: 30,
        // page: 1,
        // order_by: 'date',
        // order_type: 'asc'
        type: "WEB"
      },
      shouldSearchFeature: false,
      noDataText: "",
      collapseItemCount: 0
    };
  },
  async mounted() {
    if (this.$route.query?.releaseId) {
      this.activeRelease = this.$route.query?.releaseId;
    }
    const count = this.getNotificationCount;
    if (count > 0) {
      // set watch status
      const latestReleaseItem = this.getNotificationData[0];
      const payload = {
        ui_version_id: latestReleaseItem._id,
        ui_version_type: "WEB"
      };
      await this.setNotificationWatchStatus(payload);
      eventBus.$emit("refresh-release-notification");
    }
  },
  computed: {
    ...mapGetters("releaseNotes", {
      getReleaseList: "getReleaseList"
    }),
    ...mapGetters("header", {
      getNotificationCount: "getNotificationCount",
      getNotificationData: "getNotificationData"
    }),
    getReleaseListComputed() {
      return this.releaseList;
    },
    getActiveRelease() {
      return this.activeRelease;
    },
    getReleaseDate() {
      return (data) => {
        if (data && this.dhm__dateUtilsLib.isValid(new Date(data))) {
          return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
            data,
            "dd-MMM-yy",
            this.getUserTimeZoneString,
            this.dhm__getLocaleObj
          );
        } else {
          return "--";
        }
      };
    }
  },
  async created() {
    this.loading = true;
    try {
      await this.fetchReleaseList(this.params);
    } catch (err) {
      console.log("Something went wrong while fetching the release");
    } finally {
      this.loading = false;
    }
    this.releaseList = this.getReleaseList.filter(
      (item) => item.status === "RELEASED"
    );
    eventBus.$on("notification-item-clicked", async (args) => {
      this.loading = true;
      this.activeRelease = args.listItem?._id;
      this.releaseList = this.getReleaseList.filter(
        (item) => item.status === "RELEASED"
      );
      this.loading = false;
    });
    eventBus.$on("loadData", (args) => {
      if (args.query?.feature_title && args.query?.feature_title !== "") {
        this.shouldSearchFeature = true;
        this.loading = true;
        if (this.releaseList.length > 0) {
          this.filterdListByFeatTitle = this.releaseList.filter((item) => {
            return JSON.stringify(item)
              .toLowerCase()
              .includes(args.query?.feature_title.toLowerCase());
          });
          if (this.filterdListByFeatTitle.length === 0) {
            // this.shouldReleaseContentShow = false;
            this.noDataText = "No Data Matched";
          } else {
            this.noDataText = "";
            this.filterdListByFeatTitle.map((item, i) => {
              if (!i) {
                this.activeRelease = item._id;
              }
            });
            // this.shouldReleaseContentShow = true;
          }
          this.loading = false;
        }
      }
    });
    eventBus.$on("cleared", (args) => {
      this.shouldSearchFeature = false;
      this.activeRelease = "";
    });
    eventBus.$on("viewChanged", async (args) => {
      this.loading = true;
      this.params.type = args?.selectedView;
      try {
        await this.fetchReleaseList(this.params);
        this.releaseList = this.getReleaseList.filter(
          (item) => item.status === "RELEASED"
        );
      } catch (err) {
        console.log("Something went wrong while fetching the release");
      } finally {
        this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions("releaseNotes", {
      fetchReleaseList: "fetchReleaseList",
      setNotificationWatchStatus: "setNotificationWatchStatus"
    }),
    handleCollapseItemClick(data) {
      if (!data) {
        eventBus.$emit("collapseItemClicked", {});
      }
      this.collapseItemCount++;
      if (this.collapseItemCount > 1) {
        this.collapseItemCount--;
        eventBus.$emit("collapseItemClicked", {});
      }
    }
  }
};
</script>

<style lang="scss">
// .ql-video {
//   margin-top: 10px;
//   margin-left: 0 !important;
// }
// .colapse-item-custom-main:not(.colapse-item-custom-main ~ .colapse-item-custom-main) {
.main-container {
  position: relative;
  .no-data {
    margin-left: 46%;
    margin-top: 25%;
    opacity: 0.5;
  }
  .release-notes-legend-container {
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    .release-notes-legend {
      position: absolute;
      bottom: 0;
      display: flex;
      font-weight: bold;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      &__feature {
        display: flex;
        margin-right: 6px;
        .image {
          margin-right: 4px;
        }
        .title {
          opacity: 0.4;
        }
      }
      &__enhancement {
        display: flex;
        margin-right: 6px;
        .image {
          margin-right: 4px;
        }
        .title {
          opacity: 0.4;
        }
      }
      &__bug {
        display: flex;
        margin-right: 6px;
        .image {
          margin-right: 4px;
        }
        .title {
          opacity: 0.4;
        }
      }
    }
  }
  .colapse-item-custom-main {
    border-bottom: solid 1px #e6e6e6;
  }
  .row-item__label.truncate {
    margin-right: 0 !important;
  }
  .release-notes-collapse {
    cursor: pointer;
    .er-btn-refresh {
      visibility: hidden;
      pointer-events: none;
    }
  }
  .release-notes-collapse:nth-child(1) {
    margin-top: 24px;
  }
  .release-notes-collapse-el-tooltip__popper {
    border: 1px solid #606266 !important;
    color: #606266 !important;
  }
  .release-notes-collapse-el-tooltip__popper[x-placement^="top"]
    .popper__arrow {
    border-top-color: #606266 !important;
    bottom: -7px;
  }
  .row-item__value {
    margin-left: 5px;
    // .value-units {
    //   margin-left: 5px;
    // }
  }
  .release-notes-container {
    overflow: auto;
    height: 75vh;
    border: none;
    // margin-top: 20px;
    // varibles
    $text-color-with-dark-bg: #0a2463;
    // styles
    background-color: transparent;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 20px;
    .err-msg-row {
      margin-left: 10px;
      .el-tag {
        @include responsiveProperty(height, 25px, 28px, 31px);
        @include responsiveProperty(line-height, 22px, 25px, 28px);
        @include responsiveProperty(
          font-size,
          $app_font_size_v_medium,
          $app_font_size_small,
          $app_font_size_1
        );
      }
    }
    .cell .el-input {
      width: 100%;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      content: unset;
    }
    .el-collapse {
      border: none;
    }
    .el-collapse-item {
      background-color: #ffffff;
      color: $text-color-with-dark-bg;
      margin-bottom: 5px;
      border-radius: 5px;
      .toolbar-layout + div {
        margin-top: 10px;
      }
      .el-collapse-item__header {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        font-weight: 500;
        transition: none;
        @include responsiveProperty(height, 30px, 35px, 40px);
        @include responsiveProperty(line-height, 30px, 35px, 40px);
        background-color: transparent;
        color: $text-color-with-dark-bg;
        border-bottom: unset;
        &.is-active {
          border-bottom: 1px solid #82828217;
        }
        padding: 0px 10px;
        .collapse-header__row {
          width: 100%;
          .collapse-header__row-item {
            display: flex !important;
            .row-item__label {
              color: #828282;
              margin-right: 10px;
            }
            .row-item__value {
              font-weight: 600;
              &:first-child {
                // padding-left: 25px;
              }
            }
            .er-btn-refresh {
              padding: unset;
              .material-icons-round {
                color: #979797;
              }
            }
            &.status-item-container {
              align-items: center;
              justify-content: center;
              line-height: 1;
              & > :nth-child(2) {
                margin-right: 10px;
                margin-left: 10px;
              }
            }
          }
          .el-button--mini.is-plain.is-circle {
            padding: 3px;
          }
        }
        .el-icon-arrow-right {
          color: $text-color-with-dark-bg;
          font-weight: bolder;
          font-size: 16px;
          &:before {
            content: "\e6df";
          }
        }
        .el-collapse-item__arrow.el-icon-arrow-right {
          &.is-active {
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
          }
        }
      }
    }
    .el-collapse-item__content {
      padding: 10px;
      .col-clear-schedules {
        line-height: 3.8;
        text-align: right;
        .er-button {
          background-color: red;
          border: red;
          border-radius: 5px;
          color: white;
          font-weight: bold;
          &.is-disabled {
            opacity: 0.5;
          }
        }
      }
      .save-btn-container {
        padding-top: 15px;
      }
    }
    .pm-collapse-header-title {
      margin-bottom: 10px;
      padding-left: 10px;
    }
    .list-item__color-swatch {
      @include responsiveProperty(height, 10px, 12px, 14px, true);
      @include responsiveProperty(width, 10px, 12px, 14px, true);
      align-self: center;
      margin-right: 10px;
      @include icon-size(svg);
    }
    .display-icon-count {
      display: flex;
      justify-content: center;
    }
    .count {
      width: max-content;
      display: block;
      margin-top: -2px;
      margin-left: 6px;
      margin-right: 5px;
    }
  }

  .pond-schedule-collapse-el-tooltip__popper {
    border: 1px solid #606266 !important;
    color: #606266 !important;
  }
  .pond-schedule-collapse-el-tooltip__popper[x-placement^="top"]
    .popper__arrow {
    border-top-color: #606266 !important;
    bottom: -7px;
  }
}
</style>

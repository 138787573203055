var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{staticClass:"pondmother_container",attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false,"title":((_vm.$t('Comn_update')) + " " + (_vm.$t('Comn_pond_mother'))),"custom-class":"pondmother_action_dialog"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":_vm.handleCloseDialog,"open":_vm.handleOpenDialog}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"pondMotherObj",staticClass:"demo-pondMotherObj",attrs:{"size":"small","model":_vm.pondMotherObj}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$tc('Comn_pond', 0)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$tc('Comn_pond_name', 0),"prop":"pond","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$tc('Comn_pond_name', 0),"collapse-tags":"","value-key":"title","showSelectAll":true,"reserve-keyword":true,"multiple":"","filterable":"","size":"small"},on:{"change":_vm.handleChangePond},model:{value:(_vm.pondMotherObj.pond),callback:function ($$v) {_vm.$set(_vm.pondMotherObj, "pond", $$v)},expression:"pondMotherObj.pond"}},[_vm._l((_vm.pondsData),function(pond){return [(pond.pond_mothers.length > 0)?_c('el-option',{key:pond._id,attrs:{"label":pond.title,"value":pond._id}}):_vm._e()]})],2)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('Comn_pondmother_code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('Comn_pondmother_code'),"prop":"pond_mother_id","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$t('Comn_pondmother_code'),"no-data-text":_vm.$t('Comn_no_data_connection'),"showSelectAll":true,"disabled":_vm.disable_field,"value-key":"device_code","collapse-tags":"","reserve-keyword":true,"multiple":"","filterable":"","size":"small"},on:{"change":_vm.handlePmIdChange},model:{value:(_vm.pondMotherObj.pond_mother_id),callback:function ($$v) {_vm.$set(_vm.pondMotherObj, "pond_mother_id", $$v)},expression:"pondMotherObj.pond_mother_id"}},[_vm._l((_vm.selectedPondPms),function(pm){return _c('el-option',{key:pm._id,attrs:{"value":pm,"label":_vm.upm__getValue(pm)}})})],2)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('PM_kg_dispensed'),"immediate":"","rules":{
          required_if_not: {
            target: ['mode', 'feeder_type'],
            values: _vm.pondMotherObj
          },
          max_value: _vm.cnstsPMSettings.kg_dispense_time_secs[1],
          min_value: _vm.cnstsPMSettings.kg_dispense_time_secs[0]
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":"temp","prop":"feedlimit","error":errors[0]}},[_c('span',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.$t('PM_kg_dispensed_time_sec_for_pop_up'))},slot:"label"}),_vm._v(" "),_c('el-input-number',{attrs:{"size":"small","controls":false,"min":0,"precision":_vm.getPrecision(_vm.pondMotherObj),"disabled":_vm.columnLevelPermissions('MANAGE_DEVICES', _vm.row, 'kgDisTime'),"placeholder":_vm.$t('enter_kg_dispensed_time_sec')},on:{"change":_vm.handleKgDispense},nativeOn:{"keypress":function($event){return _vm.isNumber($event, _vm.pondMotherObj)}},model:{value:(_vm.pondMotherObj.kg_dispense_time_sec),callback:function ($$v) {_vm.$set(_vm.pondMotherObj, "kg_dispense_time_sec", $$v)},expression:"pondMotherObj.kg_dispense_time_sec"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('mode'),"immediate":"","rules":{
          required_if_not: {
            target: ['feeder_type', 'kg_dispense_time_sec'],
            values: _vm.pondMotherObj
          }
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('mode'),"prop":"mode","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$t('mode'),"no-data-text":_vm.$t('Comn_no_data_connection'),"size":"small","popper-class":"pm-dropdown","disabled":_vm.columnLevelPermissions('MANAGE_SCHEDULES', _vm.row, 'mode')},on:{"input":_vm.handleMode},model:{value:(_vm.pondMotherObj.mode),callback:function ($$v) {_vm.$set(_vm.pondMotherObj, "mode", $$v)},expression:"pondMotherObj.mode"}},_vm._l((_vm.pondModesOfOperations),function(value,key){return _c('el-option',{key:key,attrs:{"label":_vm.$t(value.lang_key),"value":value.mode}})}),1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('feeder-type'),"immediate":"","rules":{
          required_if_not: {
            target: ['mode', 'kg_dispense_time_sec'],
            values: _vm.pondMotherObj
          }
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('feeder-type'),"prop":"feeder_type","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$t('feeder-type'),"no-data-text":_vm.$t('Comn_no_data_connection'),"size":"small","popper-class":"pm-dropdown","disabled":_vm.columnLevelPermissions('MANAGE_SCHEDULES', _vm.row, '')},on:{"input":_vm.handleFeederType},model:{value:(_vm.pondMotherObj.feeder_type),callback:function ($$v) {_vm.$set(_vm.pondMotherObj, "feeder_type", $$v)},expression:"pondMotherObj.feeder_type"}},_vm._l((_vm.hopperTypes),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.lang_key),"value":item.value}})}),1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"errorwithouthighlight"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" info ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Atleast_Feed_dispense_time_Mode')))])])],1)],1),_vm._v(" "),_c('layout-toolbar',{attrs:{"slot":"footer","justify":"center"},slot:"footer"},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading,"disabled":_vm.isReadOnly || _vm.disable_field},on:{"click":_vm.submitResourceDetails}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.handleCloseDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
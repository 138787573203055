<template>
  <layout-toolbar>
    <er-list-filter
      :data="userTypes"
      value-key="key"
      search-on-key="label"
      :allow-save="true"
      :defaultCheckedKeys="getUAMListArrayIds"
      @check-change="handleChangeSelectedUserType"
      @save-filter="handelSaveFilter"
    >
      <template v-slot:label="{ item }">{{ item.label }}</template>
    </er-list-filter>
    <er-input
      inputType="search"
      v-model="userSearch"
      size="mini"
      :debounce="300"
      suffix-icon="el-icon-search"
      class="input-search-pond"
      :placeholder="$t('Comn_search')"
      :clearable="true"
      @clear="handleClear"
      @input="handleChangeSearch"
      @change="handelSearchChange"
    ></er-input>
    <div class="filler"></div>
    <er-button
      btnType="circle_add"
      :showLabel="true"
      :showIcon="true"
      size="mini"
      @click="handleClick('', 'userAccessMgmtCreate')"
    >
      <slot>
        <p>
          {{ `${$t("Comn_add")} ${$t("Comn_users")}` }}
        </p>
      </slot>
    </er-button>
  </layout-toolbar>
</template>

<script>
import { UAM_UI_STATES } from "@/constants/uiStates";
import { UAM_FILTER_TYPES } from "@/constants/filterTypes";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  data() {
    return {
      userSearch: "",
      UAM_UI_STATES,
      debounce: null
    };
  },
  computed: {
    ...mapGetters("user", {
      getUserProfile: "getUserProfile",
    }),
    getUAMListArrayIds() {
      const storedUserFilterOptions = JSON.parse(localStorage.getItem(`${this.getUserProfile._id}_userFilterOptions`));
      console.log("localStorage", storedUserFilterOptions);
      return storedUserFilterOptions?.length > 0 ? storedUserFilterOptions : this.userTypes.map((type) => type.key);
    },
    userTypes() {
      return [
        { key: "ADMIN", label: this.$tc("Usrs_admin", 1) },
        { key: "POND_OWNER", label: this.$tc("Usrs_owner", 1) },
        { key: "POND_MANAGER", label: this.$tc("Comn_manager", 1) },
        { key: "POND_WORKER", label: this.$tc("Usrs_pond_worker", 1) },
        { key: "POND_TECHNICIAN", label: this.$tc("Usrs_pond_technician", 1) },
        { key: "NO_ACCESS", label: this.$tc("Comn_no_access", 1) },
        { key: "ACCOUNT_MANAGER_SUB_USER", label: this.$tc("Skretting_technician", 1) }
      ];
    }
  },
  methods: {
    ...mapActions("userAccessManagement", {
      changeSidePanelState: "changeSidePanelState",
      changeFilters: "changeFilters"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSaveFilter() {
      this.mixPanelEventGenerator({ eventName: "Settings - Users - Filter - Save" });
    },
    handelSearchChange() {
      if (this.userSearch !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - Users - Search" });
      }
    },
    async handleChangeSelectedUserType(selectedUserTypes) {
      this.changeFilters({
        filter_type: UAM_FILTER_TYPES.USER_TYPE,
        data: selectedUserTypes
      });
    },
    handleChangeSearch() {
      this.$emit("user_search", this.userSearch);
      this.changeFilters({
        filter_type: UAM_FILTER_TYPES.SEARCH_STRING
        // data: this.userSearch,
      });
    },
    handleClear() {
      this.changeFilters({
        filter_type: UAM_FILTER_TYPES.SEARCH_STRING,
        data: this.userSearch
      });
    },
    handleClick() {
      try {
        this.$gblUAMIsHighPriorityUsersRestricted();
        this.changeSidePanelState({
          ui_state: this.UAM_UI_STATES.CREATE_NEW_SUB_USER_DETAILS
        });
      } catch (err) {
        this.ehm__errorMessages(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

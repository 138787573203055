var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"pg-table",attrs:{"element-loading-background":"white","justify":"center"}},[(!_vm.loading && _vm.Ponds.length > 0)?_c('el-row',[_c('el-row',[_c('layout-toolbar',{staticStyle:{"margin":"10px 10px"},attrs:{"justify":"end"}},[_c('er-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"inputType":"search","size":"mini","suffix-icon":"el-icon-search","placeholder":_vm.$t('Comn_search')},on:{"change":_vm.handelSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"btnType":"save","size":"mini","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly,"loading":_vm.loading},on:{"click":_vm.submitUpdatedDetails}}),_vm._v(" "),_c('er-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"btnType":"cancel","size":"mini","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.initComponent}}),_vm._v(" "),_c('er-dropdown',{staticClass:"highlight",attrs:{"placement":"bottom","trigger":"click","listOfItems":['Comn_download_as_excel', 'Comn_download_as_pdf']},on:{"item-click":_vm.handleTableDataDownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}],null,false,1598760758)},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n              file_download\n            ")])])],2)],1)],1),_vm._v(" "),_c('el-row',{staticClass:"pg-settings-table"},[_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,ref:"pgsettings",attrs:{"uniqueId":"pg-settings__table","tableData":_vm.dataValues,"total":_vm.total,"columns":_vm.tableColumns,"actionColumn":false,"merge":{ startColumnNumber: 1, endColumnNumber: 1 },"type":"white-header-table","elTableProps":_vm.tableProps,"sortMethodForColumns":_vm.sortMethods(),"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['pagination-props'],"searchOnTableData":_vm.search},scopedSlots:_vm._u([{key:"pond_title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond_title","withoutHighlightStrings":row.data.pond_title}})]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"code","withoutHighlightStrings":row.data.code}})]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"alias-name-container"},[_c('el-input',{attrs:{"placeholder":_vm.$t('enter_alias_name'),"disabled":_vm.isPGExistInSelectedPGs(row.data._id),"size":"mini"},on:{"change":function($event){return _vm.handlePGChangeInTable($event, row.data)}},model:{value:(row.data.title),callback:function ($$v) {_vm.$set(row.data, "title", $$v)},expression:"row.data.title"}})],1)]}},{key:"low_do",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"temp-container"},[_c('el-input-number',{attrs:{"placeholder":_vm.$t('Comn_low'),"disabled":_vm.isPGExistInSelectedPGs(row.data._id) || _vm.isReadOnly,"controls":false,"min":0,"size":"mini"},on:{"change":function($event){return _vm.handlePGChangeInTable($event, row.data)}},model:{value:(row.data.do_alerts_config[0].lower_limit),callback:function ($$v) {_vm.$set(row.data.do_alerts_config[0], "lower_limit", $$v)},expression:"row.data.do_alerts_config[0].lower_limit"}})],1)]}},{key:"critical_do",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"do-container"},[_c('el-input-number',{attrs:{"placeholder":_vm.$t('Comn_critical'),"disabled":_vm.isPGExistInSelectedPGs(row.data._id) || _vm.isReadOnly,"controls":false,"min":0,"size":"mini"},on:{"change":function($event){return _vm.handlePGChangeInTable($event, row.data)}},model:{value:(row.data.do_alerts_config[0].critical_lower_limit),callback:function ($$v) {_vm.$set(row.data.do_alerts_config[0], "critical_lower_limit", $$v)},expression:"row.data.do_alerts_config[0].critical_lower_limit"}})],1)]}},{key:"low_temp_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"pg-settings-table-el-tooltip__popper","placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v("\n                "+_vm._s(_vm.$t("pond-guard_temperature_info"))+" "),_c('br'),_vm._v("\n                "+_vm._s(_vm.$t("pond_guard_changes_reflects_info"))+"\n              ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"high_temp_header",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"table-col-header-container"},[_c('el-tooltip',{attrs:{"popperClass":"pg-settings-table-el-tooltip__popper","placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v("\n                "+_vm._s(_vm.$t("pond-guard_temperature_info"))+" "),_c('br'),_vm._v("\n                "+_vm._s(_vm.$t("pond_guard_changes_reflects_info"))+"\n              ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(header.label))])])],1)]}},{key:"low_temp",fn:function(ref){
var row = ref.row;
return [_c('el-row',{staticClass:"temp-container",attrs:{"type":"flex","gutter":10}},[_c('el-input-number',{attrs:{"disabled":_vm.isPGExistInSelectedPGs(row.data._id) || _vm.isReadOnly,"placeholder":_vm.$t('Comn_min'),"size":"mini","precision":1,"min":10,"max":60,"controls":false},on:{"change":function($event){return _vm.handlePGChangeInTable($event, row.data)}},model:{value:(row.data.temperature_alerts_config[0].lower_limit),callback:function ($$v) {_vm.$set(row.data.temperature_alerts_config[0], "lower_limit", $$v)},expression:"row.data.temperature_alerts_config[0].lower_limit"}})],1)]}},{key:"high_temp",fn:function(ref){
var row = ref.row;
return [_c('el-row',{staticClass:"temp-container",attrs:{"type":"flex","gutter":10}},[_c('el-input-number',{attrs:{"disabled":_vm.isPGExistInSelectedPGs(row.data._id) || _vm.isReadOnly,"placeholder":_vm.$t('Comn_max'),"size":"mini","precision":1,"controls":false,"min":10,"max":60},on:{"change":function($event){return _vm.handlePGChangeInTable($event, row.data)}},model:{value:(row.data.temperature_alerts_config[0].upper_limit),callback:function ($$v) {_vm.$set(row.data.temperature_alerts_config[0], "upper_limit", $$v)},expression:"row.data.temperature_alerts_config[0].upper_limit"}})],1)]}}],null,false,2799951591)})],1)],1):(_vm.loading)?_c('Loader'):_c('er-no-ponds',{attrs:{"tabData":_vm.tabData,"disabled":_vm.isReadOnly}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedBlowerSettings.vue
Description: This component contains UI components of feed blower tab of settings. It contains feed blower data table
-->
<template>
  <el-row
    class="settings-feedblower"
    element-loading-background="white"
    v-loading="loading"
  >
    <Loader v-if="loading"></Loader>
    <el-row>
      <layout-toolbar style="margin: 10px 10px">
        <div class="legend-row__item" v-show="!loading">
          <div :class="`color-indicatorvalve1`">&nbsp;</div>
          <div class="legend-row__label">
            {{ $t("Comn_left_pondmother") }}
          </div>
        </div>

        <er-button
          btnType="save"
          size="mini"
          v-show="!loading"
          :showLabel="true"
          :showIcon="true"
          :disabled="isReadOnly"
          :loading="loading"
          @click="submitUpdatedDetails"
        ></er-button>
        <er-button
          v-show="!loading"
          btnType="cancel"
          size="mini"
          :showLabel="true"
          :disabled="isReadOnly"
          @click="initComponent"
        >
        </er-button>
      </layout-toolbar>
    </el-row>

    <el-row v-if="Ponds.length > 0">
      <er-data-tables
        uniqueId="feed-blower-settings"
        :merge="{ startColumnNumber: 1, endColumnNumber: 1 }"
        v-show="!loading"
        :tableData="tableData"
        :total="total"
        :key="$i18n.locale"
        :loading="loading"
        :elTableProps="tableProps"
        :action-column="false"
        :columns="tableColumns"
        type="white-header-table"
      >
        <template v-slot:pond_title="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="pond_title"
            :withoutHighlightStrings="row.data.pond_title"
          />
        </template>
        <template v-slot:pond_mothers="{ row }">
          <div class="pm-contianer">
            <el-tag
              v-for="(pm, index) in getfbRowPMs(row.data)"
              :key="index"
              size="mini"
              :type="tagToValveName[pm.valve_name]"
              :color="colorToValveName[pm.valve_name]"
              closable
              @close="handleRemovePms(pm._id, row.data, pm.code || pm.title)"
            >
              {{ upm__getValue(pm) }}
            </el-tag>
            <el-tag
              type="success"
              size="mini"
              v-if="getfbRowPMs(row.data).length === 0"
            >
              {{ $t("Comn_no_pondmothers") }}
            </el-tag>
            <er-button
              type="text"
              btnType="add"
              :showIcon="true"
              size="mini"
              @click="handleAddPms($event, row.data)"
            ></er-button>
          </div>
        </template>
        <template v-slot:title="{ row }">
          <div class="alias-name-container">
            <el-input
              v-model="row.data.title"
              :placeholder="$t('enter_alias_name')"
              @change="handleFBChangeInTable($event, row.data)"
              size="mini"
            />
          </div>
        </template>
      </er-data-tables>
      <er-dialog
        :visible.sync="showDialog"
        width="30%"
        :close-on-click-modal="false"
        @open="handleOpenDialog"
        @closed="handleCloseDialog"
        :title="$tc(`Ponds_select_pond_mothers`, 2)"
        custom-class="feedblower_pm_action_dialog"
      >
        <ValidationObserver ref="dialogListForm">
          <el-form size="small">
            <ValidationProvider
              name="selectedLeftPM"
              immediate
              rules="required_if_not:selectedRightPM"
              v-slot="{ errors }"
            >
              <el-form-item
                :label="formStrings.left.label"
                label-width="120px"
                :error="errors[0]"
              >
                <er-select
                  v-model="selectedLeftPM"
                  class="select-devices"
                  value-key="_id"
                  collapse-tags
                  filterable
                  clearable
                  :placeholder="formStrings.left.placeholder"
                  :no-data-text="$t('Comn_no_pondmothers')"
                >
                  <el-option
                    v-for="pm in pmsInPond"
                    :key="pm._id"
                    :label="pm.code"
                    :value="pm"
                    :disabled="disablePondMother(pm, 'LEFT')"
                  ></el-option>
                </er-select>
              </el-form-item>
            </ValidationProvider>
            <ValidationProvider
              name="selectedRightPM"
              immediate
              rules="required_if_not:selectedLeftPM"
              v-slot="{ errors }"
            >
              <el-form-item
                :label="formStrings.right.label"
                label-width="120px"
                :error="errors[0]"
              >
                <er-select
                  v-model="selectedRightPM"
                  class="select-devices"
                  value-key="_id"
                  collapse-tags
                  filterable
                  clearable
                  :placeholder="formStrings.right.placeholder"
                  :no-data-text="$t('Comn_no_pondmothers')"
                >
                  <el-option
                    v-for="pm in pmsInPond"
                    :key="pm._id"
                    :label="pm.code"
                    :value="pm"
                    :disabled="disablePondMother(pm, 'RIGHT')"
                  ></el-option>
                </er-select>
              </el-form-item>
            </ValidationProvider>
          </el-form>
        </ValidationObserver>

        <layout-toolbar slot="footer" justify="center">
          <er-button
            size="mini"
            btnType="save"
            :loading="buttonLoading"
            :showIcon="true"
            :showLabel="true"
            @click="
              handleAssignPmsToFeedBlower(
                $event,
                selectedLeftPM,
                selectedRightPM
              )
            "
          ></er-button>
          <er-button
            size="mini"
            btnType="cancel"
            :showLabel="true"
            @click="handleCloseDialog"
          ></er-button>
        </layout-toolbar>
      </er-dialog>
    </el-row>
    <el-row v-else>{{ $t("Comn_no_data_connection") }}</el-row>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import Loader from "@/components/base/Loader";
import notificationMixin from "@/mixins/notificationMixin";
import { extend } from "vee-validate";
import { required_if } from "vee-validate/dist/rules";
import LayoutToolbar from "../../layouts/layoutToolbar.vue";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    notificationMixin,
    userPreferenceMixin
  ],
  data: function () {
    return {
      loading: false,
      showDialog: false,
      selectedLeftPM: undefined,
      selectedRightPM: undefined,
      pmsInPond: [],
      tableEditFBObj: {},
      selectedFeedBlower: undefined,
      buttonLoading: false,
      pond_mothers: [],
      feed_blower_id: "",
      alias: "",
      id: "",
      data: [],
      query: {
        page: 1,
        limit: 30
      },
      colorToValveName: {
        LEFT: "#0a2463",
        RIGHT: ""
      },
      tagToValveName: {
        LEFT: "primary",
        RIGHT: "info"
      },
      mapNameToValve: {
        1: "LEFT",
        2: "RIGHT"
      },
      mapValveNameToValve: {
        LEFT: 1,
        RIGHT: 2
      },
      tableProps: {
        // border: true,
        size: "small",
        stripe: false
        // height:250,
      },
      total: 0,
      form: {
        name: ""
      }
    };
  },
  components: {
    Loader,
    LayoutToolbar
  },
  props: {
    tabData: {
      default: {}
    }
  },
  computed: {
    ...mapGetters("feedblower", {
      getFeedBlowers: "getFeedBlowers"
    }),
    ...mapGetters("pond", {
      Ponds: "getPonds",
      getPondsObj: "getPondsObj"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      isReadOnly: "isReadOnly"
    }),
    tableData() {
      return this.getFeedBlowers.map((fb) => {
        fb.title = fb.title || fb.code;
        fb.pond_mothers = (fb.pond_mothers || []).map((pm) => {
          pm.valve_name = this.mapNameToValve[pm.valve];
          return pm;
        });
        fb.pond_title = (
          this.getPondsObj[fb.pond_id] || { title: "NO_TITLE" }
        ).title;
        return fb;
      });
    },
    formStrings() {
      return {
        left: {
          label: this.$t("Comn_left_pond_mother"),
          placeholder: `${this.$t("Comn_select")} ${this.$t(
            "Comn_pond_mother"
          )}`
        },
        right: {
          label: this.$t("Comn_right_pond_mother"),
          placeholder: `${this.$t("Comn_select")} ${this.$t(
            "Comn_pond_mother"
          )}`
        }
      };
    },

    tableColumns() {
      return {
        pond_title: {
          prop: "pond_title",
          label: this.$t("Comn_pond_name"),
          sortable: true,
          sortMethod: this.$commonUtils.naturalSort("title"),
          minWidth: 100
        },
        device_code: {
          prop: "code",
          label: this.$t("feed-blower-id"),
          minWidth: 100
        },
        pond_mothers: {
          prop: "pond_mothers",
          label: this.$t("PM_pondmothers"),
          minWidth: 100
        },
        title: {
          prop: "title",
          label: this.$t("Comn_alias_name"),
          // minWidth: 100,
          minWidth: 100
        }
      };
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("feedblower", {
      fetchAllFeedBlowers: "fetchAllFeedBlowers",
      updateFeedBlowerPms: "updateFeedBlowerPms",
      removePmsFromFeedBlower: "removePmsFromFeedBlower",
      updateFeedBlowerDetails: "updateFeedBlowerDetails"
    }),
    handleFBChangeInTable(event, pg) {
      this.$set(this.tableEditFBObj, pg._id, pg);
    },

    disablePondMother(pm, valveName) {
      const valveToOppValue = {
        LEFT: "selectedRightPM",
        RIGHT: "selectedLeftPM"
      };
      const casesToDisable = [
        pm &&
          pm.feed_blower_id &&
          pm.feed_blower_id !== this.selectedFeedBlower._id,
        pm &&
          this[valveToOppValue[valveName]] &&
          this[valveToOppValue[valveName]]._id === pm._id
      ];
      return casesToDisable.includes(true);
    },
    getfbRowPMs(feedBlower) {
      const pondPmsObj = this.getPondsObj[
        feedBlower.pond_id
      ].pond_mothers.reduce((accPms, pm) => {
        accPms[pm._id] = pm;
        return accPms;
      }, {});
      return this.$lodash
        .cloneDeep(feedBlower.pond_mothers)
        .map((pm) => {
          return {
            ...pm,
            title: (pondPmsObj[pm._id] || { title: pm.code }).title
          };
        })
        .sort((a, b) => (a.valve || 0) - (b.valve || 0))
        .filter((x) => x._id);
    },
    async submitUpdatedDetails() {
      this.loading = true;
      const payLoadObj = {};
      try {
        Object.values(this.tableEditFBObj).forEach((fb) => {
          if (!payLoadObj[fb._id]) {
            payLoadObj[fb._id] = fb;
          }
        });
        console.log(payLoadObj);
        const payload = Object.values(payLoadObj).map((fb) => {
          return {
            title: fb.title,
            feed_blower_id: fb._id
          };
        });
        if (payload.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_no_changes_found", {
                  item: this.$t("Comn_pond_guard")
                })
              }
            ]
          };
        } else {
          await this.updateFeedBlowerDetails(payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("feed_blower_details_updated_success"),
            duration: 5000,
            type: "success"
          });
          await this.initComponent();
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    initValidationStrings() {
      extend("required_if_not", {
        ...required_if,
        // params: ['target']
        validate: (value, args) => {
          const target_value = args.target;
          return Boolean(target_value || value);
        },
        message: this.$t("Comn_atleast_one_pm")
      });
    },
    async handleAssignPmsToFeedBlower(event, pm1, pm2) {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) {
        return;
      }
      const pond_mother_ids = {
        pond_mothers: [
          { pm: this.selectedLeftPM, valve: this.mapValveNameToValve.LEFT },
          {
            pm: this.selectedRightPM,
            valve: this.mapValveNameToValve.RIGHT
          },
          { pm: this.selectedLeftPM, valve: this.mapValveNameToValve.LEFT }
        ]
          .filter((x) => x.pm)
          .map((x) => ({ _id: x.pm._id, valve: x.valve }))
      };
      const data = {
        pondmother_ids: pond_mother_ids,
        feed_blower_id: this.selectedFeedBlower._id
      };
      this.buttonLoading = true;
      try {
        await this.updateFeedBlowerPms(data);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Comn_add_devices_user"),
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.buttonLoading = false;
        this.showDialog = false;
        await this.initComponent();
      }
      this.handleCloseDialog();
    },
    handleOpenDialog() {
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
      this.initValidationStrings();
    },
    handleCloseDialog() {
      this.selectedRightPM = undefined;
      this.selectedLeftPM = undefined;
      this.initValidationStrings();
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
      this.showDialog = false;
    },
    handleAddPms(event, feedBlower) {
      this.selectedFeedBlower = this.$lodash.cloneDeep(feedBlower);
      const mapValveNameToPm = this.selectedFeedBlower.pond_mothers.reduce(
        (acc, pm) => {
          acc[pm.valve_name] = pm;
          return acc;
        },
        {}
      );
      this.selectedLeftPM = mapValveNameToPm.LEFT;
      this.selectedRightPM = mapValveNameToPm.RIGHT;
      this.pmsInPond = this.getPondsObj[feedBlower.pond_id].pond_mothers;
      this.showDialog = true;
    },
    async handleRemovePms(pm_id, feed_blower, pm_code) {
      await this.nofm__deleteConfirmation(
        pm_code,
        this.$tc("Comn_feed_blower", 1)
      );
      this.loading = true;
      const pm_ids = { pond_mother_ids: [pm_id] };
      const payload = {
        pond_mother_ids: pm_ids,
        feed_blower_id: feed_blower._id
      };
      try {
        await this.removePmsFromFeedBlower(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Pond_mother_deleted_success"),
          duration: 5000,
          type: "success"
        });
        this.selectedLeftPM = "";
        this.selectedRightPM = "";
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.selectedRightPM = undefined;
        this.selectedLeftPM = undefined;
        this.initValidationStrings();
        this.$nextTick(() => {
          this.$refs.dialogListForm.reset();
        });
        await this.initComponent();
      }
    },
    async initComponent() {
      this.upm__setDataKeys("code", "title");
      this.tableEditFBObj = {};
      this.loading = true;
      try {
        await Promise.all([
          this.fetchAllPonds({
            location_id: this.getCurrUserLocation._id,
            status: ["ACTIVE", "INACTIVE"],
            get_all: true
          }),
          this.fetchAllFeedBlowers({
            location_id: this.getCurrUserLocation._id,
            un_assigned_only: false,
            get_all: true
          })
        ]);
        this.data = this.Ponds;
        this.total = this.Ponds.filter((x) => x.feed_blowers.length).length;
      } catch (err) {
        this.erroMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.settings-feedblower {
  .legend-row__item {
    margin-right: auto !important;
    height: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    .color-indicatorvalve1 {
      background-color: $selected-color;
      @include responsiveProperty(
        width,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      @include responsiveProperty(
        height,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      margin-right: 5px;
      border-radius: 3px;
    }

    .legend-row__label {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      font-weight: 400;
    }
  }
  .el-tag.el-tag--info {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    border-radius: 10px;
  }
  .el-tag.el-tag--primary {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #e9e9eb;
    border-radius: 10px;
  }
  .el-tag.el-tag--primary .el-tag__close:hover {
    background-color: #f4f4f5 !important;
  }
  .el-tag.el-tag--primary .el-tag__close {
    color: #909399;
  }

  .el-tag.el-tag--success {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    border-radius: 10px;
  }
  .el-input {
    width: fit-content;
  }
  .feedblower_pm_action_dialog {
    background: #fcfcfc;
    border-radius: 10px;

    .el-input--small .el-input__inner {
      @include responsiveProperty(width, 205px, 227px, 250px);
    }

    .el-form-item--small .el-form-item__content,
    .el-form-item--small .el-form-item__label {
      margin-left: 0px !important;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      @include responsiveProperty(min-width, 145px, 175px, 220px);
    }
  }
}
</style>

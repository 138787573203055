<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: assignSetsToPonds.vue
Description: This file contians UI components used to assign sets to ponds. It is being used in schedules tab in settings
-->
<template>
  <el-row class="schedules-ponds-container">
    <el-col :span="24" v-loading="pondsLoading" class="inside-container">
      <el-col :span="24" style="padding: 16px 16px 0px 16px">
        <el-row>
          <el-col>
            <h4>{{ $t("PM_assing-set_pond_short") }}</h4>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="assign-resources-row-style">
          <el-col :span="9">
            <er-select
              v-model="feedTemplateSelected"
              :placeholder="$t('PM_select_set')"
              value-key="_id"
              size="mini"
              @change="handleChangeFeedTemplate"
            >
              <el-option
                v-for="feedTemplate in sortedFeedTemplates"
                :key="feedTemplate._id"
                :label="feedTemplate.title"
                :value="feedTemplate"
              ></el-option>
            </er-select>
          </el-col>
          <el-col :span="9" class="header-pond-container">
            <er-select
              :value="getSelectFTCurrLocationPondIds(feedTemplateSelected)"
              :placeholder="$t('Ponds_select_pond_name')"
              filterable
              multiple
              collapse-tags
              :disabled="feedTemplateSelected._id === ''"
              value-key="_id"
              @change="handlePondsSelectionChange"
              size="mini"
            >
              <el-option
                v-for="pond in Ponds"
                :key="pond._id"
                :disabled="disablePondOptionInSet(pond._id)"
                :label="pond.title"
                :value="pond._id"
              ></el-option>
            </er-select>
          </el-col>
          <el-col :span="6">
            <layout-toolbar class="btn-container">
              <er-button
                btnType="save"
                size="mini"
                :showLabel="true"
                :showIcon="true"
                :disabled="isReadOnly || feedTemplateSelected._id === ''"
                :loading="pondsLoading"
                @click="updateFeedTemplate"
              />

              <er-button
                btnType="cancel"
                size="mini"
                :showLabel="true"
                :disabled="isReadOnly || feedTemplateSelected._id === ''"
                @click="initComponent"
              ></er-button>
            </layout-toolbar>
          </el-col>
        </el-row>
        <el-row>
          <hr />
        </el-row>
      </el-col>
      <el-col :span="24" class="table-container">
        <er-data-tables
          ref="assignSetstoPonds"
          :tableData="sortedFeedTemplates"
          :key="$i18n.locale"
          size="mini"
          :actionColumn="false"
          :el-table-props="tableProps"
          uniqueId="schedules-assign-sets-settings__table"
          :columns="tableColumns"
          :page-size="$constants.table['page-size']"
          :pagination-props="$constants.table['pagination-props']"
          type="transparent-header-table"
        >
          <template slot="empty">
            <span>
              <img class="no-data__img" src="@/assets/no_data.svg" />
            </span>
            <span class="no-data__text">
              <h4>{{ $t("Comn_no_data_connection") }}</h4>
            </span>
          </template>
          <el-table-column
            prop="title"
            :label="$t('template-name')"
            min-width="200"
          ></el-table-column>
          <template v-slot:ponds="{ row }">
            <div
              class="pm-tag-list"
              v-if="getSelectFTCurrLocationPonds(row.data).length > 0"
            >
              <el-tag
                v-for="pond in getSelectFTCurrLocationPonds(row.data)"
                :disable-transitions="true"
                :key="pond._id"
                effect="dark"
                size="mini"
                :closable="true"
                @close="handleRemovePond(row.data, pond._id)"
                >{{ pond.title }}</el-tag
              >
              <!-- @close="$emit('removePMTag',index)" -->
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
        </er-data-tables>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import FeedTemplate from "@/model/feedtemplate";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AssignSetsToPonds",
  mixins: [errorHandlerMixin, notificationMixin],
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      feedTemplateSelected: new FeedTemplate(),
      resourceSelected: {
        _id: "",
        ponds: []
      },
      pondList: [],
      pondsLoading: false,
      feedLoading: false,
      selectedFTClearPonds: [],
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "title",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      pondIdsRemovedFromSelectedFT: []
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    currLocation() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    currLocationId() {
      return this.currLocation._id;
    },
    disablePondOptionInSet() {
      return pondId => {
        const isPondInSelectedTemplate = (
          this.feedTemplateSelected.obj_locations[this.currLocationId] || {
            pond_ids: []
          }
        ).pond_ids.includes(pondId);
        if (isPondInSelectedTemplate) return false;
        const isPondInPondsWithoutTemplate = this.pondsNotInTemplate.includes(
          pondId
        );
        if (isPondInPondsWithoutTemplate) return false;
        const isPondInPondIdsRemovedFromSelectedFT = this.pondIdsRemovedFromSelectedFT.includes(
          pondId
        );
        if (isPondInPondIdsRemovedFromSelectedFT) return false;
        return true;
      };
    },
    tableColumns() {
      return {
        title: {
          prop: "title",
          label: this.$t("sets"),
          sortable: true,
          minWidth: 100
        },
        ponds: {
          prop: "ponds",
          label: this.$t("assigned-ponds"),
          // sortable: true
          minWidth: 300
        }
      };
    },
    currFeedTemplate: {
      get() {
        return this.$store.getters["schedules/getCurrentFeedTemplate"];
      },
      set(value) {
        this.$store.dispatch("schedules/setCurrentFeedTemplate", value);
      }
    },
    FeedTemplates() {
      return Object.values(this.$store.getters["schedules/getMapftIdFT"]);
    },
    sortedFeedTemplates() {
      const newTemps = this.$lodash.cloneDeep(this.FeedTemplates);
      return newTemps
        .map(x => {
          x.obj_locations = x.ponds.reduce((acc, x) => {
            if (acc[x.location_id] === undefined) {
              acc[x.location_id] = { ponds: [], pond_ids: [] };
            }
            acc[x.location_id].pond_ids.push(x._id);
            acc[x.location_id].ponds.push(x);
            acc[x.location_id].ponds.sort((a, b) =>
              this.$commonUtils.alphaNumericComparator(a.title, b.title)
            );
            return acc;
          }, {});
          return x;
        })
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
    },
    Ponds() {
      return this.$store.getters["pond/getPonds"];
    },
    ObjPondIdPond() {
      return this.$store.getters["pond/getPondsObj"];
    },
    pondsNotInTemplate() {
      return this.$store.getters["schedules/getPondsNotInTemplate"].map(
        pond => {
          return pond._id;
        }
      );
    },
    pondsInTemplate() {
      return this.$store.getters["schedules/getPondsInTemplate"];
    },
    resources: function() {
      return this.$store.getters["resource/getArrResources"];
    },
    getSelectFTCurrLocationPonds: function() {
      return template =>
        (
          template.obj_locations[this.currLocationId] || {
            ponds: []
          }
        ).ponds;
    },
    getSelectFTCurrLocationPondIds: function() {
      return template =>
        (
          template.obj_locations[this.currLocationId] || {
            pond_ids: []
          }
        ).pond_ids;
    }
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent() {
      this.$nextTick(() => {
        if (this.$refs.assignSetstoPonds) {
          this.$refs.assignSetstoPonds.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
      this.feedTemplateSelected = new FeedTemplate();
    },
    getPondTitle(pondId) {
      return (this.ObjPondIdPond[pondId] || { title: this.$t("empty") }).title;
    },
    async handleClear($event, componentName) {
      if (componentName === "feed") {
        this.selectedFTClearPonds = this.feedTemplateSelected.ponds;
        this.feedTemplateSelected.ponds = [];
        this.feedTemplateSelected.ponds = [];
      } else {
        this.resourceSelected = {
          _id: "",
          ponds: []
        };
      }
    },
    handlePondsSelectionChange(arrPondIds) {
      if (!this.feedTemplateSelected.obj_locations[this.currLocationId]) {
        this.feedTemplateSelected.obj_locations[this.currLocationId] = {
          ponds: [],
          pond_ids: []
        };
      }
      this.feedTemplateSelected.obj_locations[
        this.currLocationId
      ].pond_ids = arrPondIds;
      this.feedTemplateSelected = Object.assign(
        new FeedTemplate(),
        this.feedTemplateSelected
      );
      const actualPondIdsInSelectedFT = this.getSelectFTCurrLocationPonds(
        this.feedTemplateSelected
      ).map(x => x._id);
      const currSelectedFTPondIds = new Set(...arrPondIds);
      this.pondIdsRemovedFromSelectedFT = actualPondIdsInSelectedFT.filter(
        x => !currSelectedFTPondIds.has(x)
      );
      this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Assign Sets to Pond - Ponds Dropdown" })
    },
    handleChangeFeedTemplate: function(value) {
      this.feedTemplateSelected = this.$lodash.cloneDeep(value);
      this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Assign Sets to Pond - Sets Dropdown" })
    },
    updateFeedTemplate: async function(type = "select") {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.pondsLoading = true;
        const { obj_locations } = this.feedTemplateSelected;
        const arrPonds = Object.values(obj_locations)
          .map(x => x.pond_ids)
          .filter(x => x.length > 0)
          .flat(1);
        if (arrPonds.length === 0 && type !== "table") {
          const err = {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("please-select-a-pond")
              }
            ]
          };
          this.ehm__errorMessages(err, true);
          this.pondsLoading = false;
        } else {
          this.feedTemplateSelected.location_id = this.currLocation._id;
          this.feedTemplateSelected.ponds = arrPonds;
          await this.$store.dispatch(
            "schedules/updateFeedTemplate",
            this.feedTemplateSelected
          );
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message:
              type === "table"
                ? this.$t("Set_updated_successfully")
                : this.$t("PM_set_assigned_successfully"),
            duration: 5000,
            type: "success"
          });
          this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Assign Sets to Pond Table - Save" })
          this.initComponent();
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.pondsLoading = false;
      }
    },
    async handleRemovePond(data, pondId) {
      const payload = this.$lodash.cloneDeep(data);
      payload.location_id = this.currLocation._id;
      payload.ponds = payload.ponds
        .filter(({ _id }) => _id !== pondId)
        .map(x => x._id);
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.nofm__deleteConfirmation(
          this.ObjPondIdPond[pondId].title,
          payload.title
        );
        this.pondsLoading = true;
        // assign to selected comp data
        // update feedTemplate from db
        await this.$store.dispatch("schedules/updateFeedTemplate", payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Set_updated_successfully"),
          duration: 5000,
          type: "success"
        });
        this.initComponent();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.pondsLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.schedules-ponds-container {
  @include responsiveProperty(--table-gutter, 308px, 318px, 325px);
  border-top: 1px solid #dddddd;
  padding: 10px;
  background: white;
  display: flex;
  justify-content: center;
  .inside-container {
    background: #fcfcfc;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 10px;
  }
  hr {
    color: #f0f0f0;
    margin-bottom: 2px;
  }
  .el-select__tags-text {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .el-tag {
    display: flex;
    align-items: baseline;
  }
  .header-pond-container {
    .el-input {
      width: 220px;
    }
  }
  .table-container {
    padding-left: 29px;
    padding-right: 29px;
    // padding-bottom: 29px;
    .el-tag--dark {
      height: unset;
      display: inline-flex;
      align-items: center;
      padding: 0px 7px;
      background-color: #f2f0f9 !important;
      border-color: #f2f0f9;
      font-size: 11px;
      color: #0a2463;
      margin-top: 2px;
      margin-right: 2px;
      .el-tag__close.el-icon-close {
        background-color: #0a2463;
        font-size: 11px;
        top: 0px;
      }
      .el-tag__close {
        color: #fff !important;
      }
    }
    .el-tag .el-icon-close {
      border-radius: 50%;
      text-align: center !important;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }
    .el-tag--mini .el-icon-close {
      margin-left: -3px;
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    .el-table td .cell *,
    .er-data-tables .transparent-header-table .el-table th .cell * {
      color: #0a2463;
      background: white;
      border-radius: 20px;
    }
    .er-data-tables__header-row {
      background: transparent;
    }
    .pm-tag-list {
      overflow: auto;
    }
  }
  h4 {
    // margin-bottom: 17px;
    font-size: 13px;
    line-height: 24px;
    color: #0a2463;
    opacity: 0.9;
  }
  .assign-resources-row-style {
    margin-top: 10px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"settings-devices",attrs:{"element-loading-background":"white"}},[(!_vm.loading && _vm.Ponds.length > 0)?_c('el-row',[_c('layout-toolbar',{staticClass:"pond-table__header",attrs:{"type":"flex","justify":"end"}},[_c('div',{staticStyle:{"display":"flex","margin-right":"auto"}},[_c('el-radio-group',{attrs:{"value":_vm.selectedStatus,"size":"small"},on:{"input":_vm.handleStatusChange}},[_c('el-radio-button',{staticClass:"is-plain",attrs:{"label":"ACTIVE"}},[_vm._v("\n            "+_vm._s(_vm.$t("Comn_active"))+"\n          ")]),_vm._v(" "),_c('el-radio-button',{staticClass:"is-plain",attrs:{"label":"INACTIVE"}},[_vm._v("\n            "+_vm._s(_vm.$t("Comn_inactive"))+"\n          ")]),_vm._v(" "),_c('el-radio-button',{staticClass:"is-plain",attrs:{"label":"ALL"}},[_vm._v("\n            "+_vm._s(_vm.$t("Comn_all"))+"\n          ")])],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":3.5}},[_c('er-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"inputType":"search","debounce":500,"size":"mini","placeholder":_vm.$t('Pond_search_pond'),"suffix-icon":"el-icon-search"},on:{"change":_vm.handelSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('er-dropdown',{staticClass:"highlight",attrs:{"placement":"bottom","trigger":"click","listOfItems":['Comn_download_as_excel', 'Comn_download_as_pdf']},on:{"item-click":_vm.handleTableDataDownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}],null,false,1598760758)},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n            file_download\n          ")])])],2)],1),_vm._v(" "),_c('el-row',[_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,ref:"deviceSettings",attrs:{"uniqueId":"device-settings__table","tableData":_vm.tableData,"elTableProps":_vm.tableProps,"action-column":false,"unique-id":"devices-tab-table","columns":_vm.computedColumns,"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['pagination-props'],"type":"white-header-table","searchOnTableData":_vm.search},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"title","withoutHighlightStrings":row.data.title}})]}},{key:"pond_guards",fn:function(ref){
var row = ref.row;
return [(row.data['pond_guards'].length > 0)?_c('div',{staticClass:"tag-list"},_vm._l((row.data['pond_guards']),function(pg){return _c('el-tag',{key:pg._id,attrs:{"disable-transitions":true,"effect":"dark","size":"mini","closable":!_vm.isReadOnly},on:{"close":function($event){return _vm.handleRemoveDevice('pond_guard', pg, row.data)}}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits('pond_guards'),"withoutHighlightStrings":_vm.upm__getValue(pg)}})],1)}),1):_c('span',[_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":"info"}},[_vm._v("\n              "+_vm._s(_vm.$t("Comn_na"))+"\n            ")])],1)]}},{key:"pond_mothers",fn:function(ref){
var row = ref.row;
return [(row.data['pond_mothers'].length > 0)?_c('div',{staticClass:"tag-list"},[_vm._l((_vm.getSortedDeviceList(row.data['pond_mothers'])),function(pm){return [_c('el-tag',{key:pm._id,style:(_vm.getBackGroundColorStyleString(pm, row.data._id)),attrs:{"disable-transitions":true,"effect":"dark","size":"mini","closable":!_vm.disablePondDeletion(pm)},on:{"close":function($event){return _vm.handleRemoveDevice('pond_mother', pm, row.data)}}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits('pond_mothers'),"withoutHighlightStrings":_vm.upm__getValue(pm)}})],1)]})],2):_c('span',[_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":"info"}},[_vm._v("\n              "+_vm._s(_vm.$t("Comn_na"))+"\n            ")])],1)]}},{key:"shrimp_talks",fn:function(ref){
var row = ref.row;
return [(row.data['shrimp_talks'].length > 0)?_c('div',{staticClass:"tag-list"},_vm._l((_vm.getSortedDeviceList(row.data['shrimp_talks'])),function(st){return _c('el-tag',{key:st._id,attrs:{"disable-transitions":true,"effect":"dark","size":"mini","closable":!_vm.isReadOnly},on:{"close":function($event){return _vm.handleRemoveDevice('shrimp_talk', st, row.data)}}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits('shrimp_talks'),"withoutHighlightStrings":_vm.upm__getValue(st) || st.code}})],1)}),1):_c('span',[_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":"info"}},[_vm._v("\n              "+_vm._s(_vm.$t("Comn_na"))+"\n            ")])],1)]}},{key:"feed_blowers",fn:function(ref){
var row = ref.row;
return [(row.data['feed_blowers'].length > 0)?_c('div',{staticClass:"tag-list"},_vm._l((_vm.getSortedDeviceList(row.data['feed_blowers'])),function(fb){return _c('el-tag',{key:fb._id,attrs:{"disable-transitions":true,"effect":"dark","size":"mini","closable":!_vm.isReadOnly},on:{"close":function($event){return _vm.handleRemoveDevice('feed_blower', fb, row.data)}}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits('feed_blowers'),"withoutHighlightStrings":_vm.upm__getValue(fb) || fb.code}})],1)}),1):_c('span',[_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":"info"}},[_vm._v("\n              "+_vm._s(_vm.$t("Comn_na"))+"\n            ")])],1)]}},{key:"shrimp_snaps",fn:function(ref){
var row = ref.row;
return [(row.data['shrimp_snaps'].length > 0)?_c('div',{staticClass:"tag-list"},_vm._l((_vm.getSortedDeviceList(row.data['shrimp_snaps'])),function(ss){return _c('el-tag',{key:ss._id,attrs:{"disable-transitions":true,"effect":"dark","size":"mini","closable":!_vm.isReadOnly},on:{"close":function($event){return _vm.handleRemoveDevice('shrimp_snap', ss, row.data)}}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits('shrimp_snaps'),"withoutHighlightStrings":_vm.upm__getValue(ss) || ss.code}})],1)}),1):_c('span',[_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":"info"}},[_vm._v("\n              "+_vm._s(_vm.$t("Comn_na"))+"\n            ")])],1)]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('er-button',{attrs:{"btnType":"add","type":"text","size":"mini","disabled":_vm.isReadOnly,"showLabel":true,"showIcon":true},on:{"click":function($event){return _vm.handleAddDevice(row.data)}}})],1)]}}],null,false,1931408841)})],1),_vm._v(" "),_c('div',{key:_vm.component},[_c(_vm.component,{tag:"component",attrs:{"showDialog":_vm.dialogVisible,"pond_details":_vm.selectedPond},on:{"close_dialog":_vm.closeDialog}})],1)],1):(_vm.loading)?_c('Loader'):_c('er-no-ponds',{attrs:{"tabData":_vm.tabData,"disabled":_vm.isReadOnly}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: devices.vue
Description:  This component contains UI components of devices tab of settings. It contains devices data table
-->
<template>
  <el-row
    class="settings-devices"
    element-loading-background="white"
    v-loading="loading"
  >
    <el-row v-if="!loading && Ponds.length > 0">
      <layout-toolbar class="pond-table__header" type="flex" justify="end">
        <div style="display: flex; margin-right: auto">
          <el-radio-group
            :value="selectedStatus"
            size="small"
            @input="handleStatusChange"
          >
            <el-radio-button label="ACTIVE" class="is-plain">
              {{ $t("Comn_active") }}
            </el-radio-button>
            <el-radio-button label="INACTIVE" class="is-plain">
              {{ $t("Comn_inactive") }}
            </el-radio-button>
            <el-radio-button label="ALL" class="is-plain">
              {{ $t("Comn_all") }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <el-col :span="3.5">
          <er-input
            v-show="!loading"
            inputType="search"
            :debounce="500"
            size="mini"
            :placeholder="$t('Pond_search_pond')"
            v-model="search"
            suffix-icon="el-icon-search"
            @change="handelSearchChange"
          ></er-input>
        </el-col>
        <er-dropdown
          placement="bottom"
          @item-click="handleTableDataDownload"
          trigger="click"
          class="highlight"
          :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
        >
          <template class="el-dropdown-link download_button" slot="suffix">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
        </er-dropdown>
      </layout-toolbar>
      <el-row>
        <er-data-tables
          ref="deviceSettings"
          uniqueId="device-settings__table"
          v-show="!loading"
          :tableData="tableData"
          :key="$i18n.locale"
          :elTableProps="tableProps"
          :action-column="false"
          unique-id="devices-tab-table"
          :columns="computedColumns"
          :page-size="$constants.table['page-size']"
          :pagination-props="$constants.table['pagination-props']"
          type="white-header-table"
          :searchOnTableData="search"
        >
          <template v-slot:title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="title"
              :withoutHighlightStrings="row.data.title"
            />
          </template>
          <template v-slot:pond_guards="{ row }">
            <div class="tag-list" v-if="row.data['pond_guards'].length > 0">
              <el-tag
                v-for="pg in row.data['pond_guards']"
                :key="pg._id"
                :disable-transitions="true"
                effect="dark"
                size="mini"
                :closable="!isReadOnly"
                @close="handleRemoveDevice('pond_guard', pg, row.data)"
              >
                <er-highlight-search-component
                  :highlightStrings="row.data.highlightStrings"
                  :individualRowData="row.data"
                  :keyName="getDeviceNameUnits('pond_guards')"
                  :withoutHighlightStrings="upm__getValue(pg)"
                />
              </el-tag>
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
          <template v-slot:pond_mothers="{ row }">
            <div class="tag-list" v-if="row.data['pond_mothers'].length > 0">
              <template
                v-for="pm in getSortedDeviceList(row.data['pond_mothers'])"
              >
                <el-tag
                  :key="pm._id"
                  :disable-transitions="true"
                  effect="dark"
                  size="mini"
                  :closable="!disablePondDeletion(pm)"
                  @close="handleRemoveDevice('pond_mother', pm, row.data)"
                  :style="getBackGroundColorStyleString(pm, row.data._id)"
                >
                  <er-highlight-search-component
                    :highlightStrings="row.data.highlightStrings"
                    :individualRowData="row.data"
                    :keyName="getDeviceNameUnits('pond_mothers')"
                    :withoutHighlightStrings="upm__getValue(pm)"
                  />
                </el-tag>
              </template>
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
          <template v-slot:shrimp_talks="{ row }">
            <div class="tag-list" v-if="row.data['shrimp_talks'].length > 0">
              <el-tag
                v-for="st in getSortedDeviceList(row.data['shrimp_talks'])"
                :disable-transitions="true"
                :key="st._id"
                effect="dark"
                size="mini"
                :closable="!isReadOnly"
                @close="handleRemoveDevice('shrimp_talk', st, row.data)"
              >
                <er-highlight-search-component
                  :highlightStrings="row.data.highlightStrings"
                  :individualRowData="row.data"
                  :keyName="getDeviceNameUnits('shrimp_talks')"
                  :withoutHighlightStrings="upm__getValue(st) || st.code"
              /></el-tag>
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
          <template v-slot:feed_blowers="{ row }">
            <div class="tag-list" v-if="row.data['feed_blowers'].length > 0">
              <el-tag
                v-for="fb in getSortedDeviceList(row.data['feed_blowers'])"
                :disable-transitions="true"
                :key="fb._id"
                effect="dark"
                size="mini"
                :closable="!isReadOnly"
                @close="handleRemoveDevice('feed_blower', fb, row.data)"
              >
                <er-highlight-search-component
                  :highlightStrings="row.data.highlightStrings"
                  :individualRowData="row.data"
                  :keyName="getDeviceNameUnits('feed_blowers')"
                  :withoutHighlightStrings="upm__getValue(fb) || fb.code"
              /></el-tag>
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
          <template v-slot:shrimp_snaps="{ row }">
            <div class="tag-list" v-if="row.data['shrimp_snaps'].length > 0">
              <el-tag
                v-for="ss in getSortedDeviceList(row.data['shrimp_snaps'])"
                :disable-transitions="true"
                :key="ss._id"
                effect="dark"
                size="mini"
                :closable="!isReadOnly"
                @close="handleRemoveDevice('shrimp_snap', ss, row.data)"
              >
                <er-highlight-search-component
                  :highlightStrings="row.data.highlightStrings"
                  :individualRowData="row.data"
                  :keyName="getDeviceNameUnits('shrimp_snaps')"
                  :withoutHighlightStrings="upm__getValue(ss) || ss.code"
              /></el-tag>
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
          <template v-slot:action="{ row }">
            <div style="display: flex">
              <er-button
                btnType="add"
                type="text"
                size="mini"
                :disabled="isReadOnly"
                :showLabel="true"
                :showIcon="true"
                @click="handleAddDevice(row.data)"
              ></er-button>
            </div>
          </template>
        </er-data-tables>
      </el-row>
      <div :key="component">
        <component
          :is="component"
          :showDialog="dialogVisible"
          :pond_details="selectedPond"
          @close_dialog="closeDialog"
        ></component>
      </div>
    </el-row>
    <Loader v-else-if="loading"></Loader>
    <er-no-ponds :tabData="tabData" :disabled="isReadOnly" v-else />
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import addDevicesToPond from "./addDevicesToPond";
import Loader from "@/components/base/Loader";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import { ALL_USERS as FEATURE_ALL_USERS } from "@/middleware/featureToggleManager";
import exportFileDownload from "@/mixins/exportFileDownload";
// const = createNamespacedHelpers("pond")
export default {
  mixins: [
    errorHandlerMixin,
    featureToggleMixin,
    userPreferenceMixin,
    exportFileDownload
  ],
  components: {
    addDevicesToPond,
    Loader
  },
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      data: [],
      loading: false,
      dialogVisible: false,
      selectedStatus: "ACTIVE",
      currentFilters: new Map(),
      newFeedRecord: {},
      multiSeletedPonds: [],
      searchStr: "",
      component: "",
      selectedPond: null,
      tableProps: {
        size: "small",
        stripe: false,
        defaultSort: {
          prop: "title",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      disabled: true,
      search: "",
      getDeviceFilters: [
        {
          prop: [
            "title",
            "pond_guards",
            "pond_mothers",
            "shrimp_talks",
            "feed_blowers"
          ],
          value: "",
          filterFn: (row, filter) => {
            return filter.prop.some((prop) => {
              const deviceObject = {
                pond_guards: "pond_guards",
                pond_mothers: "pond_mothers",
                shrimp_talks: "shrimp_talks",
                feed_blowers: "feed_blowers"
              };
              if (prop === deviceObject[prop]) {
                if (row[prop].length > 0) {
                  return row[prop]
                    .map((x) => x[this.units].toLowerCase())
                    .includes(filter.value.toLowerCase());
                } else {
                  return "";
                }
              } else {
                return row[prop]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase());
              }
            });
          }
        },
        {
          prop: ["status"],
          value: "ACTIVE",
          filterFn: (row, filter) => {
            if (filter.value === "ALL") return true;
            return row.status === filter.value;
          }
        }
      ]
    };
  },
  async mounted() {
    await this.initComponent();
  },
  computed: {
    ...mapGetters("pond", {
      Ponds: "getPonds",
      getPondsObj: "getPondsObj",
      getPondDeviceKeysByDevicesAvailable: "getPondDeviceKeysByDevicesAvailable"
    }),
    ...mapGetters("user", {
      getDeviceTypesEnabledForUser: "getDeviceTypesEnabledForUser",
      getCurrUserLocation: "getCurrUserLocation",
      isReadOnly: "isReadOnly",
      getPreferredUnits: "getPreferredUnits"
    }),

    units() {
      if (this.getPreferredUnits.device_name.toLowerCase() === "code") {
        return "code";
      } else {
        return "title";
      }
    },
    ...mapGetters("schedules", {
      getMapPondIdFT: "getMapPondIdFT"
    }),

    disablePondDeletion() {
      return (pm) => {
        return [this.isReadOnly].some((x) => x);
      };
    },
    tableData() {
      if (this.selectedStatus === "ALL") {
        return this.Ponds;
      } else {
        return this.Ponds.filter((x) => x.status === this.selectedStatus);
      }
    },
    getBackGroundColorStyleString() {
      return (pm, pondId) => {
        const color = pm.shrimp_talk_id
          ? (
              this.getIndexOnStsByPondId(pondId)[pm.shrimp_talk_id] || {
                color: "#44a1ff"
              }
            ).color
          : "#44a1ff";
        return `background-color:${color};`;
      };
    },
    getIndexOnStsByPondId() {
      return (pondId) => {
        const stObj = {};
        (this.getPondsObj[pondId] || { shrimp_talks: [] }).shrimp_talks.forEach(
          (st) => {
            stObj[st._id] = st;
          }
        );
        return stObj;
      };
    },
    deviceColumnsTranslations: function() {
      return {
        pond_guards: () => this.$t("Comn_pond_guard"),
        pond_mothers: () => this.$tc("Comn_pond_mother", 2),
        shrimp_talks: () => "ShrimpTalk",
        feed_blowers: () => "FeedBlower",
        shrimp_snaps: () => "ShrimpSnap"
      };
    },
    tableColumns() {
      return {
        title: {
          prop: "title",
          label: this.$t("Comn_name"),
          sortable: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          sortMethod: this.$commonUtils.naturalSort("title"),
          enableSearch: true,
          searchProperty: "title",
          downloadOption: true
        },
        ...this.getPondDeviceKeysByDevicesAvailable.reduce(
          (acc, pondDeviceKey) => {
            acc[pondDeviceKey] = {
              prop: pondDeviceKey,
              featureAllowedUsers: FEATURE_ALL_USERS,
              label: this.deviceColumnsTranslations[pondDeviceKey](),
              minWidth: 150,
              enableSearch: true,
              searchProperty:
                this.getPreferredUnits.device_name === "code"
                  ? `${pondDeviceKey}.code`
                  : `${pondDeviceKey}.title`,
              downloadOption: true
            };
            return acc;
          },
          {}
        ),
        action: {
          prop: "action",
          featureAllowedUsers: FEATURE_ALL_USERS,
          label: this.$t("Comn_actions"),
          default: true,
          minWidth: 80,
          downloadOption: false
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.title.label,
          dataKey: "title",
          emptyValue: "--",
          name: "title",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },

        {
          header: this.$t("Comn_pond_devices_type"),
          dataKey: "Comn_pond_devices_type",
          emptyValue: "--",
          name: "device_type",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.$t("Comn_device_code"),
          dataKey: "Comn_device_code",
          emptyValue: "--",
          name: "device_code",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        }
      ];
    },

    computedColumns: function() {
      return this.tableColumns;
    }
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("shrimptalk", {
      fetchAShrimpTalk: "fetchAShrimpTalk"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.search !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - Devices - Search" });
      }
    },
    downloadData() {
      const data = this.$refs.deviceSettings.$refs.dataTables.sortedData.reduce(
        (
          acc,
          { title, pond_mothers, pond_guards, shrimp_talks, feed_blowers }
        ) => {
          const devicerecords = [
            {
              value: pond_mothers,
              deviceType: "PondMother",
              device_key: "POND_MOTHER"
            },
            {
              value: pond_guards,
              deviceType: "PondGuard",
              device_key: "POND_GUARD"
            },
            {
              value: shrimp_talks,
              deviceType: "ShrimpTalk",
              device_key: "SHRIMP_TALK"
            },
            {
              value: feed_blowers,
              deviceType: "FeedBlower",
              device_key: "FEED_BLOWER"
            }
          ]
            .filter((x) =>
              this.getDeviceTypesEnabledForUser.includes(x.device_key)
            )
            .map((item) =>
              this.normalizeDeviceRecords(
                title,
                item.value.length === 0 ? [{}] : item.value,
                item.deviceType
              )
            );
          acc.push(...devicerecords);
          console.log(acc.flat(1));
          return acc.flat(1);
        },
        []
      );
      return data;
    },
    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - Devices - Download" });
    },
    pdfdownload() {
      this.exp__Download(this.downloadData(), this.downloadColumns, "pdf");
    },
    xceldownload() {
      this.exp__Download(this.downloadData(), this.downloadColumns, "xcel");
    },
    normalizeDeviceRecords(title, deviceArray, deviceType) {
      return deviceArray
        .map((x, index) => {
          if (JSON.stringify(x) !== '{}') {
            return {
              title,
              Comn_pond_devices_type: deviceType,
              Comn_device_code:
                this.getPreferredUnits.device_name === "code" ? x.code : x.title
            };
          }
        }).filter(x => x !== undefined)
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(
            a.Comn_device_code,
            b.Comn_device_code
          )
        );
    },
    getSortedDeviceList(arrDevices) {
      return [...arrDevices].sort((device1, device2) =>
        this.$commonUtils.alphaNumericComparator(
          device1.title || device1.code,
          device2.title || device2.code
        )
      );
    },
    handleStatusChange(status) {
      this.selectedStatus = status;
      this.mixPanelEventGenerator({ eventName: "Settings - Devices - " + status });
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.fetchAllPonds({
          status: ["ACTIVE", "INACTIVE"],
          include: [
            ...this.getPondDeviceKeysByDevicesAvailable,
            "title",
            "status"
          ],
          location_id: this.getCurrUserLocation._id,
          get_all: true
        });
        this.upm__setDataKeys("code", "title");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.devicesettings) {
            this.$refs.devicesettings.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    closeDialog(event) {
      this.dialogVisible = false;
      this.component = "";
      if (event !== "close") {
        this.initComponent();
        this.mixPanelEventGenerator({ eventName: "Settings - Devices - Add Device - Save" });
      }
    },
    getDeviceNameUnits(device) {
      const obj = {
        pond_guards:
          this.getPreferredUnits.device_name === "alias_name"
            ? "pond_guards.title"
            : "pond_guards.code",
        pond_mothers:
          this.getPreferredUnits.device_name === "alias_name"
            ? "pond_mothers.title"
            : "pond_mothers.code",
        shrimp_talks:
          this.getPreferredUnits.device_name === "alias_name"
            ? "shrimp_talks.title"
            : "shrimp_talks.code",
        feed_blowers:
          this.getPreferredUnits.device_name === "alias_name"
            ? "feed_blowers.title"
            : "feed_blowers.code",
        shrimp_snaps:
          this.getPreferredUnits.device_name === "alias_name"
            ? "shrimp_snaps.title"
            : "shrimp_snaps.code"
      };
      return obj[device];
    },
    handleAddDevice(pond) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.component = "addDevicesToPond";
        this.dialogVisible = true;
        this.selectedPond = pond;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async handleRemoveDevice(deviceType, device, pond) {
      let loading;
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        loading = this.$loading({ fullscreen: true });
        let confirm_message = this.$t("Comn_delete_confirm_msg", {
          item: this.upm__getValue(device),
          source: pond.title
        });
        if (deviceType === "pond_mother") {
          confirm_message = this.$t("pm_delete_confirm_message", {
            device_title: this.upm__getValue(device)
          });
          if (device.shrimp_talk_id) {
            const { pond_mothers } = await this.fetchAShrimpTalk(
              device.shrimp_talk_id
            );
            const stPm = pond_mothers.find((x) => x._id === device._id);
            if (stPm) {
              const { is_master } = stPm;
              if (is_master) {
                throw {
                  type: "FAIL_TO_SAVE",
                  errors: [
                    { message: this.$t("selected_feeder_is_main_feeder_pond") }
                  ]
                };
              }
            }
          }
        }
        if (deviceType === "shrimp_talk") {
          confirm_message = this.$t("Comn_delete_confirm_msg", {
            item:
              this.getPreferredUnits.device_name === "code"
                ? device.code
                : device.title,
            source: pond.title
          });
        }
        await this.$confirm(confirm_message, this.$t("Comn_delete"), {
          confirmButtonText: this.$t("Comn_confirm"),
          cancelButtonText: this.$t("Comn_cancel"),
          cancelButtonClass: "er-button er-button--default",
          confirmButtonClass: "er-button er-button--confirm",
          type: "warning"
        });
        if (deviceType === "pond_mother") {
          await this.notificationHandler(
            "pondmother/unAssignPondMothers",
            "pond_mother",
            device._id
          );
        } else if (deviceType === "shrimp_talk") {
          await this.notificationHandler(
            "shrimptalk/unAssignShrimpTalks",
            "shrimp_talk",
            device._id
          );
        } else if (deviceType === "pond_guard") {
          await this.notificationHandler(
            "pondguard/unAssignPondGuard",
            "pond_guard",
            device._id
          );
        } else if (deviceType === "feed_blower") {
          const id = {
            feed_blower_ids: [device._id]
          };
          const data = { pond_id: pond._id, devicedetails: id };
          await this.notificationHandler(
            "feedblower/unAssignFeedBlowersFromPond",
            "feed_blower",
            data
          );
        } else if (deviceType === "shrimp_snap") {
          const data = {
            shrimp_snaps: [device._id]
          };
          await this.notificationHandler(
            "shrimpsnap/unAssignFromPond",
            "shrimp_snap",
            data
          );
        }
        await this.initComponent();
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        if (loading) {
          loading.close();
        }
      }
    },
    getDeleteDeviceMessageKey(deviceType) {
      const ErrorMessageMap = {
        pond_mother: "Pond_mother_deleted_success",
        pond_guard: "Pond_guard_deleted_success",
        shrimp_snap: "Shrimp_snap_deleted_success",
        shrimp_talk: "Shrimp_talk_deleted_success",
        feed_blower: "Feed_blower_deleted_success"
      };
      return ErrorMessageMap[deviceType] || "empty";
    },
    async notificationHandler(store_action_name, deviceType, reqPayload) {
      try {
        await this.$store.dispatch(store_action_name, reqPayload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t(this.getDeleteDeviceMessageKey(deviceType)),
          duration: 5000,
          type: "success"
        });
        this.initComponent();
      } catch (err) {
        // this.ehm__errorMessages(err, true);
        this.$notify({
          title: this.$t("failed"),
          message: err.message,
          duration: 5000,
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss">
@mixin align-cell-content {
  text-align: left;
  justify-items: flex-start;
  align-items: flex-start;
}
@mixin cell-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.settings-devices {
  @include responsiveProperty(--table-gutter, 222px, 222px, 227px);
  .server-side-pagination-table {
    .el-table--small td,
    .el-table--small th {
      padding: 3px 0 !important;
    }

    padding: 0 12px;
    .el-table td .cell,
    .el-table th .cell {
      @include cell-font-size;
      @include align-cell-content;
      // * {
      //   @include align-cell-content;
      // }
    }
  }
  .status-radio-btn-group {
    margin-left: 10px;
    .el-radio-button__inner {
      padding: 7px 15px;
    }
  }
  #device-settings__table {
    padding: 0 12px;
  }
  .pond-table__header {
    padding: 12px 10px;
  }
  .tag-list {
    display: flex;
    max-height: 100px;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  .el-table th .cell {
    font-weight: 500;
  }
  .el-tag--dark {
    height: unset;
    display: inline-flex;
    align-items: center;
    padding: 0px 7px;
    background-color: #f2f0f9 !important;
    border-color: #f2f0f9;
    border-radius: 25px;
    @include cell-font-size;
    color: #0a2463;
    margin-top: 2px;
    margin-right: 2px;
    .el-tag__close.el-icon-close {
      background-color: #0a2463;
      @include icon-size(icon-font, prefix);
      top: 0px;
      //   @include
      // height: 18px;
      // width: 18px;
      // line-height: 21px;
      @include respond-to(medium-screens) {
        height: 19px;
        width: 19px;
        line-height: 19px;
      }
      @include respond-to(wide-screens) {
        height: 21px;
        width: 21px;
        line-height: 21px;
      }
      // &.el-icon-close:before {
      //   content: "\e6d8";
      // }
    }
  }
  .el-pagination__sizes {
    .el-input--mini .el-input__inner {
      height: 22px;
      line-height: 22px;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $button-color;
    color: #fff;
  }
  .el-pagination {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .el-radio-group {
    .el-radio-button__inner {
      padding: 7px 15px;
    }
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #ffffff;
    background: $button-color;
    border-color: $button-color;
    box-shadow: -1px 0 0 0 $button-color;
  }
  .el-radio-button__orig-radio + .el-radio-button__inner {
    color: #0a2463fe;
  }
  .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
    opacity: 0.6;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"aquasim-container",attrs:{"justify":"center","element-loading-background":"white"}},[(!_vm.loading)?_c('el-row',[_c('layout-toolbar',{staticStyle:{"margin":"10px 10px"},attrs:{"justify":"end"}},[_c('er-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"inputType":"search","debounce":500,"size":"mini","placeholder":_vm.$t('Comn_search'),"suffix-icon":"el-icon-search"},on:{"change":_vm.handelSearchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"save","size":"mini","showLabel":true,"showIcon":true,"loading":_vm.loading,"disabled":_vm.isReadOnly},on:{"click":_vm.submitUpdatedDetails}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","size":"mini","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.initComponent}}),_vm._v(" "),_c('er-dropdown',{staticClass:"highlight",attrs:{"v-show":!_vm.loading,"placement":"bottom","trigger":"click","listOfItems":['Comn_download_as_excel', 'Comn_download_as_pdf']},on:{"item-click":_vm.handleTableDataDownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}],null,false,1598760758)},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n            file_download\n          ")])])],2)],1),_vm._v(" "),_c('el-row',{staticClass:"aqua-table",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":9,"md":11,"lg":11,"xl":9}},[_c('er-data-tables',{key:_vm.$i18n.locale,ref:"aquasim",attrs:{"tableData":_vm.tableData,"size":"mini","actionColumn":false,"el-table-props":_vm.tableProps,"uniqueId":"schedules-assign-sets-settings__table","columns":_vm.tableColumns,"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['pagination-props'],"type":"white-header-table"},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"title","withoutHighlightStrings":row.data.title}})]}},{key:"aqua_sim_pond_id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"aquasim-id-container"},[_c('el-input',{attrs:{"placeholder":'Aquasim',"size":"mini"},on:{"input":function($event){return _vm.handleChange(row.data)}},model:{value:(row.data.aqua_sim_pond_id),callback:function ($$v) {_vm.$set(row.data, "aqua_sim_pond_id", $$v)},expression:"row.data.aqua_sim_pond_id"}})],1)]}},{key:"aqua_sim_auto_schedules",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"popperClass":"aquasim-container-el-tooltip__popper","disabled":_vm.canEnableAutoSimToPond(row.data),"placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.getPromptMessage(row.data)))]),_vm._v(" "),_c('el-switch',{attrs:{"disabled":!_vm.canEnableAutoSimToPond(row.data)},on:{"change":function($event){return _vm.handleChange(row.data)}},model:{value:(row.data.aqua_sim_auto_schedules),callback:function ($$v) {_vm.$set(row.data, "aqua_sim_auto_schedules", $$v)},expression:"row.data.aqua_sim_auto_schedules"}})],1)]}}],null,false,1608210265)})],1)],1)],1):(_vm.loading)?_c('Loader'):_c('er-empty')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
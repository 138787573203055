<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: aquasim.vue
Description: This file contains UI components used in aquasim tab from settings. It contains aquasim data table. Here were you update aquasim basic device details
-->
<template>
  <el-row
    v-loading="loading"
    justify="center"
    class="aquasim-container"
    element-loading-background="white"
  >
    <el-row v-if="!loading">
      <layout-toolbar justify="end" style="margin: 10px 10px">
        <er-input
          v-show="!loading"
          inputType="search"
          :debounce="500"
          size="mini"
          :placeholder="$t('Comn_search')"
          v-model="search"
          suffix-icon="el-icon-search"
          @change="handelSearchChange"
        ></er-input>
        <er-button
          btnType="save"
          size="mini"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          :disabled="isReadOnly"
          @click="submitUpdatedDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          size="mini"
          :showLabel="true"
          :disabled="isReadOnly"
          @click="initComponent"
        >
        </er-button>
        <er-dropdown
          :v-show="!loading"
          placement="bottom"
          @item-click="handleTableDataDownload"
          trigger="click"
          class="highlight"
          :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
        >
          <template class="el-dropdown-link download_button" slot="suffix">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
        </er-dropdown>
      </layout-toolbar>
      <el-row type="flex" justify="center" class="aqua-table">
        <el-col :span="9" :md="11" :lg="11" :xl="9">
          <er-data-tables
            ref="aquasim"
            :tableData="tableData"
            :key="$i18n.locale"
            size="mini"
            :actionColumn="false"
            :el-table-props="tableProps"
            uniqueId="schedules-assign-sets-settings__table"
            :columns="tableColumns"
            :page-size="$constants.table['page-size']"
            :pagination-props="$constants.table['pagination-props']"
            type="white-header-table"
          >
            <template v-slot:title="{ row }">
              <er-highlight-search-component
                :highlightStrings="row.data.highlightStrings"
                :individualRowData="row.data"
                keyName="title"
                :withoutHighlightStrings="row.data.title"
              />
            </template>
            <template v-slot:aqua_sim_pond_id="{ row }">
              <div class="aquasim-id-container">
                <el-input
                  v-model="row.data.aqua_sim_pond_id"
                  :placeholder="'Aquasim'"
                  size="mini"
                  @input="handleChange(row.data)"
                />
              </div>
            </template>
            <template v-slot:aqua_sim_auto_schedules="{ row }">
              <el-tooltip
                popperClass="aquasim-container-el-tooltip__popper"
                :disabled="canEnableAutoSimToPond(row.data)"
                placement="top"
                effect="light"
              >
                <div slot="content">{{ getPromptMessage(row.data) }}</div>
                <el-switch
                  v-model="row.data.aqua_sim_auto_schedules"
                  :disabled="!canEnableAutoSimToPond(row.data)"
                  @change="handleChange(row.data)"
                >
                </el-switch>
              </el-tooltip>
            </template>
          </er-data-tables>
        </el-col>
      </el-row>
    </el-row>
    <Loader v-else-if="loading"></Loader>
    <er-empty v-else />
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validate, extend } from "vee-validate";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import exportFileDownload from "@/mixins/exportFileDownload";
import Loader from "@/components/base/Loader";
export default {
  name: "aquasim",
  mixins: [errorHandlerMixin, exportFileDownload],
  props: {
    tabData: {
      default: {}
    }
  },
  components: {
    Loader
  },
  data: function() {
    return {
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "pond",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      validations: {
        aqua_sim_pond_id: {
          key: "aqua_sim_pond_id",
          rules: "requiredWithSchedule",
          name: this.$t("Aqsim_aquasim_id")
        }
      },
      loading: true,
      tableData: [],
      search: ""
    };
  },
  watch: {
    search: function (newVal, oldVal) {
      this.handelSearch()
    }
  },
  async mounted() {
    extend("requiredWithSchedule", {
      params: ["pond_id"],
      validate: (pond, params) => {
        if (pond.aqua_sim_auto_schedules && !pond.aqua_sim_pond_id) {
          return this.$t("Comn_field_is_required_in_item", {
            field: "{_field_}",
            item: pond.pond_title
          });
        } else {
          return true;
        }
      }
    });
    this.initComponent();
  },
  computed: {
    ...mapGetters("pond", {
      Ponds: "getPonds"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      isReadOnly: "isReadOnly"
    }),
    tableColumns() {
      return {
        title: {
          prop: "title",
          label: this.$t("Comn_pond_name"),
          sortable: true,
          enableSearch: true,
          searchProperty: "title"
        },
        aqua_sim_pond_id: {
          prop: "aqua_sim_pond_id",
          label: this.$t("Aqsim_aquasim"),
          minWidth: 120,
          searchProperty: "aqua_sim_pond_id",
          enableSearch: true
        },
        aqua_sim_auto_schedules: {
          prop: "aqua_sim_auto_schedules",
          label: this.$t("Aqsim_aqua_sch"),
          minWidth: 100
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.title.label,
          dataKey: "title",
          emptyValue: "--",
          name: "title",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.aqua_sim_pond_id.label,
          dataKey: "aqua_sim_pond_id",
          emptyValue: "--",
          name: "sim_id",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.aqua_sim_auto_schedules.label,
          dataKey: (value) => {
            return value.aqua_sim_auto_schedules
              ? this.$t("Comn_enable")
              : this.$t("Comn_disable");
          },
          emptyValue: "--",
          name: "auto_schedules",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        }
      ];
    }
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("aquasim", {
      updateAquasim: "updateAquasim"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.search !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - Aquasim - Search" });
      }
    },
    handelSearch() {
      this.tableData = this.search !== ""
        ? this.tableData.filter((x) =>
            x.title.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.$lodash.cloneDeep(this.Ponds).map((x) => {
          x.aqua_sim_auto_schedules =
            this.canEnableAutoSimToPond(x) && x.aqua_sim_auto_schedules;
          return x;
        });
        return this.tableData;
    },
    getPromptMessage(pond) {
      if (pond.status !== "ACTIVE") {
        return this.$t("PM_culture_not_started_msg");
      } else if (pond.pond_mothers.length <= 0) {
        return this.$t("Comn_no_pondmothers");
      }
    },
    canEnableAutoSimToPond(pond) {
      const conditions = [
        pond.status === "ACTIVE",
        pond.pond_mothers.length > 0
      ];
      return conditions.every((x) => x);
    },
    async initComponent() {
      this.loading = true;
      try {
        await this.fetchAllPonds({
          location_id: this.getCurrUserLocation._id,
          get_all: true,
          status: ["ACTIVE", "INACTIVE"]
        });
        this.tableData = this.handelSearch();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          if (this.$refs.aquasim) {
            this.$refs.aquasim.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
      }
    },
    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - Aquasim - Download" });
    },
    xceldownload() {
      this.exp__Download(
        this.$refs.aquasim.$refs.dataTables.sortedData.map((x) => {
          return {
            title: x.title,
            aqua_sim_pond_id: x.aqua_sim_pond_id,
            aqua_sim_auto_schedules: x.aqua_sim_auto_schedules
          };
        }),
        this.downloadColumns,
        "xcel"
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.aquasim.$refs.dataTables.sortedData.map((x) => {
          return {
            title: x.title,
            aqua_sim_pond_id: x.aqua_sim_pond_id,
            aqua_sim_auto_schedules: x.aqua_sim_auto_schedules
          };
        }),
        this.downloadColumns,
        "pdf"
      );
    },

    getPondFromId(id) {
      return this.Ponds.find(({ _id }) => _id === id);
    },
    async validatePayload(aquasimData) {
      const errors = [];
      const errorData = aquasimData.map(async (record, index) => {
        const list = Object.entries(this.validations).map(
          async ([key, value]) => {
            console.log(record);
            const field = key === "aqua_sim_pond_id" ? record : record[key];
            const valRes = await validate(field, value.rules, {
              name: value.name
            });
            if (!valRes.valid) {
              return {
                message: valRes.errors[0]
              };
            }
          }
        );
        const resolvedProm = await Promise.all(list);
        return resolvedProm;
      });
      const list = await Promise.all(errorData);
      const data = list.flat();
      data.forEach((err) => {
        if (err) {
          errors.push(err);
        }
      });
      if (errors.length > 0) {
        throw {
          type: "FAIL_TO_SAVE",
          errors: errors
        };
      }
    },
    async submitUpdatedDetails() {
      try {
        this.loading = true;
        const aquasimData = this.tableData
          .filter(({ isChanged }) => {
            console.log(isChanged);
            return isChanged;
          })
          .map(({ _id, aqua_sim_pond_id, aqua_sim_auto_schedules, title }) => {
            return {
              pond_id: _id,
              pond_title: title,
              aqua_sim_pond_id,
              aqua_sim_auto_schedules
            };
          });

        if (aquasimData.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_no_changes_found", {
                  item: this.$t("Comn_shrimptalk")
                })
              }
            ]
          };
        }

        await this.validatePayload(aquasimData);
        await this.updateAquasim(aquasimData);
        this.mixPanelEventGenerator({ eventName: "Settings - Aquasim - Save" });
        await this.initComponent();
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Aquasim_upd_succ_msg"),
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        this.$notify({
          title: this.$t("Comn_error"),
          message: this.$t("Aquasim_upd_error_msg", { Aquasim: err.response.data.message.split(' ')[0] + " " + (err.response.data.message.split(' ')[1] !== 'is' ? err.response.data.message.split(' ')[1] : '') }),
          duration: 5000,
          type: "error"
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleChange(data) {
      data.isChanged = true;
      this.tableData = this.tableData.reduce((acc, curr) => {
        if (curr.pond_id === data.pond_id) {
          curr = data;
          curr.isChanged = true;
        }
        acc.push(curr);
        return acc;
      }, []);
    }
  }
};
</script>

<style lang="scss">
.aquasim-container {
  @include responsiveProperty(--table-gutter, 222px, 222px, 227px);
  .aqua-table {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .aquasim-id-container {
    .el-input--mini {
      width: 140px;
    }
  }
}
.aquasim-container-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}
.aquasim-container-el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #606266 !important;
  bottom: -7px;
}
// .loading-style {
//   .el-loading-spinner {
//     top: 25%;
//     margin-top: 0px;
//   }
// }

// .loading-style {
//   .el-loading-spinner {
//     top: 25% !important;
//     margin-top: 0px !important;
//   }
// }
</style>

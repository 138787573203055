var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{staticClass:"gateway_action_dialog_container",attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false,"title":((_vm.$t('Comn_update')) + " " + (_vm.$t('Comn_gateway'))),"custom-class":"gateway_action_dialog"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":_vm.handleCloseDialog,"open":_vm.handleOpenDialog}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"gatewayObj",staticClass:"demo-gatewayObj",attrs:{"size":"small","model":_vm.gatewayObj}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$tc('Comn_gateway_id', 0)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$tc('Comn_gateway_id', 0),"prop":"gateway_id","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$tc('Comn_gateway_id', 0),"collapse-tags":"","value-key":"title","showSelectAll":true,"reserve-keyword":true,"multiple":"","filterable":"","size":"small"},on:{"change":_vm.handleChangeGatewayID},model:{value:(_vm.gatewayObj.gateway_id),callback:function ($$v) {_vm.$set(_vm.gatewayObj, "gateway_id", $$v)},expression:"gatewayObj.gateway_id"}},[_vm._l((_vm.getGatewayDataContainer),function(gateway){return [(gateway.code)?_c('el-option',{key:gateway.code,attrs:{"label":gateway.code,"value":gateway.code}}):_vm._e()]})],2)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('Comn_location_label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('Comn_location_label'),"prop":"location_id","error":errors[0]}},[_c('er-select',{attrs:{"disabled":_vm.disable_field,"placeholder":_vm.$t('Comn_location_label'),"collapse-tags":"","value-key":"title","showSelectAll":true,"reserve-keyword":true,"multiple":"","filterable":"","size":"small"},on:{"change":_vm.handleChangeLocationID},model:{value:(_vm.gatewayObj.location_id),callback:function ($$v) {_vm.$set(_vm.gatewayObj, "location_id", $$v)},expression:"gatewayObj.location_id"}},[_vm._l((_vm.getLocationOfUser),function(location){return [(location._id)?_c('el-option',{key:location._id,attrs:{"label":location.name,"value":location._id}}):_vm._e()]})],2)],1)]}}])}),_vm._v(" "),(_vm.showAllowOtherLocationDisplay)?_c('ValidationProvider',{attrs:{"name":_vm.$t('allow_other_location_device'),"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"allow-other-location",attrs:{"label":_vm.$t('allow_other_location_device'),"prop":"allow_other_location_device","error":errors[0]}},[_c('el-checkbox',{attrs:{"size":"small","disabled":_vm.disable_field || _vm.shouldAllowOtherLocationDisable},on:{"change":_vm.handleAllowOtherLocationDevice},model:{value:(_vm.gatewayObj.allow_other_location_device),callback:function ($$v) {_vm.$set(_vm.gatewayObj, "allow_other_location_device", $$v)},expression:"gatewayObj.allow_other_location_device"}})],1)]}}],null,false,1423661347)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('manual_wifi_name_label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('manual_wifi_name_label'),"prop":"alias_name","error":errors[0]}},[_c('span',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.$t('manual_wifi_name_label'))},slot:"label"}),_vm._v(" "),_c('el-input',{attrs:{"size":"small","controls":false,"disabled":_vm.disable_field,"placeholder":_vm.$t('manual_wifi_name_label')},on:{"change":_vm.handleWifiName},nativeOn:{"keypress":function($event){return _vm.checkWifiName($event)}},model:{value:(_vm.gatewayObj.manual_wifi_name),callback:function ($$v) {_vm.$set(_vm.gatewayObj, "manual_wifi_name", $$v)},expression:"gatewayObj.manual_wifi_name"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":_vm.$t('manual_wifi_password_label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":_vm.$t('manual_wifi_password_label'),"prop":"alias_name","error":errors[0]}},[_c('span',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.$t('manual_wifi_password_label'))},slot:"label"}),_vm._v(" "),_c('el-input',{attrs:{"size":"small","controls":false,"disabled":_vm.disable_field,"placeholder":_vm.$t('manual_wifi_password_label')},on:{"change":_vm.handleWifiPassword},model:{value:(_vm.gatewayObj.manual_wifi_password),callback:function ($$v) {_vm.$set(_vm.gatewayObj, "manual_wifi_password", $$v)},expression:"gatewayObj.manual_wifi_password"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"errorwithouthighlight"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" info ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Comn_location_required')))])])],1)],1),_vm._v(" "),_c('layout-toolbar',{attrs:{"slot":"footer","justify":"center"},slot:"footer"},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading,"disabled":_vm.isReadOnly || _vm.disable_field},on:{"click":_vm.submitGatewayDetails}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":_vm.handleCloseDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"schedules-resources-container"},[_c('el-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.feedLoading),expression:"feedLoading"}],staticClass:"inside-container",attrs:{"span":24}},[_c('el-col',{staticStyle:{"padding":"16px 16px 0px 16px"}},[_c('el-row',[_c('el-col',[_c('h4',[_vm._v(_vm._s(_vm.$t("PM_assign_resource_Pond_short")))])])],1),_vm._v(" "),_c('el-row',{staticClass:"assign-resources-row-style",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":9}},[_c('er-select',{attrs:{"placeholder":_vm.$t('select_feed'),"value-key":"_id","size":"mini"},on:{"change":_vm.handleChangeFeedTemplate},model:{value:(_vm.resourceSelected),callback:function ($$v) {_vm.resourceSelected=$$v},expression:"resourceSelected"}},_vm._l((_vm.getSortedResources),function(resource){return _c('el-option',{key:resource._id,attrs:{"label":((resource.name) + "-" + (resource.feed_type)),"value":resource}})}),1)],1),_vm._v(" "),_c('el-col',{key:_vm.getPonds.length,staticClass:"header-pond-container",attrs:{"span":9}},[_c('er-select',{key:_vm.resourceSelected._id,attrs:{"placeholder":_vm.$t('Ponds_select_pond_name'),"filterable":"","disabled":!_vm.resourceSelected._id,"multiple":"","collapse-tags":"","size":"mini","value-key":"_id"},on:{"change":_vm.handlePondsSelectionChange},model:{value:(_vm.resourceSelected.ponds),callback:function ($$v) {_vm.$set(_vm.resourceSelected, "ponds", $$v)},expression:"resourceSelected.ponds"}},[_vm._l((_vm.getPonds),function(pond){return [_c('el-option',{key:pond._id,attrs:{"label":pond.title,"value":pond._id}},[_vm._v(_vm._s(pond.title))])]})],2)],1),_vm._v(" "),_c('el-col',{attrs:{"span":6}},[_c('layout-toolbar',{staticClass:"btn-container"},[_c('er-button',{attrs:{"btnType":"save","size":"mini","showLabel":true,"showIcon":true,"disabled":_vm.isReadOnly ||
                  !_vm.resourceSelected._id ||
                  !_vm.resourceSelected.ponds ||
                  _vm.resourceSelected.ponds.length === 0,"loading":_vm.feedLoading},on:{"click":_vm.updateFeedToPond}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","size":"mini","showLabel":true,"disabled":_vm.isReadOnly},on:{"click":function($event){return _vm.handleClear($event, 'resource')}}})],1)],1)],1),_vm._v(" "),_c('el-row',[_c('hr')])],1),_vm._v(" "),_c('el-col',{staticClass:"table-container"},[_c('er-data-tables',{key:_vm.$i18n.locale,ref:"assignResourcetoPonds",attrs:{"size":"mini","tableData":_vm.getTableData,"actionColumn":false,"el-table-props":_vm.tableProps,"uniqueId":"schedules-assign-resources-settings__table","columns":_vm.tableColumns,"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['pagination-props'],"type":"transparent-header-table"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
                  var row = ref.row;
return [(row.data.feed_type)?_c('p',[_vm._v("\n            "+_vm._s(((row.data.name) + "-" + (row.data.feed_type)))+"\n          ")]):_c('p',[_vm._v(_vm._s(("" + (row.data.name))))])]}},{key:"ponds",fn:function(ref){
                  var row = ref.row;
return [(row.data.ponds.length > 0)?_c('div',{staticClass:"pm-tag-list"},_vm._l((row.data.ponds),function(pond){return _c('el-tag',{key:pond._id,attrs:{"disable-transitions":true,"effect":"dark","size":"mini","closable":true},on:{"close":function($event){return _vm.handleRemovePond(row.data, pond._id)}}},[_vm._v(_vm._s(pond.title))])}),1):_c('span',[_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":"info"}},[_vm._v("\n              "+_vm._s(_vm.$t("Comn_na"))+"\n            ")])],1)]}}])},[_c('template',{slot:"empty"},[_c('span',[_c('img',{staticClass:"no-data__img",attrs:{"src":require("@/assets/no_data.svg")}})]),_vm._v(" "),_c('span',{staticClass:"no-data__text"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Comn_no_data_connection")))])])]),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('Resource_name'),"width":150}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
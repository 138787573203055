<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: settings.vue
Description:This file contains the tab components.userProfile,units,userAccessManagement,pondTab,devices,pondMotherSettings,scheduleSettings,gateway,shrimpTalkSettings,pondGuardSettings,feedBlowerSettings,locationManagement,aquasim
-->
<template>
  <layout-pondlogs class="settings-tab-component">
    <template slot="layout-pondlogs-scrollable-main">
      <er-card>
        <er-top-route-nav
          type="tabs"
          :items="computedTabItems"
        ></er-top-route-nav>
        <!-- </simplebar> -->
        <component
          :is="computedComponentName"
          @change-tab="handleChangeTabTo"
          :tabData="components[computedComponentName]"
        ></component>
      </er-card>
    </template>
  </layout-pondlogs>
</template>

<script>
import userProfile from "@/components/settings/userProfile";
import pondTab from "@/components/settings/pondTab";
import pondMotherSettings from "@/components/settings/pondMotherSettings";
import pondGuardSettings from "@/components/settings/pondGuardSettings";
import feedBlowerSettings from "@/components/settings/feedBlowerSettings";
import scheduleSettings from "@/components/settings/scheduleSettings";
import devices from "@/components/settings/devices";
import userAccessManagement from "@/components/settings/userAccessManagement/index";
import locationManagement from "@/components/settings/locationManagement";
import gateway from "@/components/settings/gateway";
import shrimpTalkSettings from "@/components/settings/shrimpTalkSettings";
import units from "@/components/settings/units";
import aquasim from "@/components/settings/aquasim";
import {
  permissionsToStringMap as permissions,
  highPriorityUserTypes,
  adminUserTypes,
  // userTypesWithAccess,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
import { mapGetters } from "vuex";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import { ALL_USERS as FEATURES_ALL_USERS } from "@/middleware/featureToggleManager";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import filtersMixin from "@/mixins/filtersMixin";
const tabToPermission = {
  userProfile: {
    name: "userProfile",
    keyToValidateAccess: "PERMISSIONS", // "USER_TYPES",
    // USER_TYPES: userTypesWithAccess,
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_DEVICES, permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES,
      permissions.MANAGE_OVERVIEW, permissions.MANAGE_MEDICINE, permissions.MANAGE_HARVEST,
      permissions.MANAGE_ABW, permissions.MANAGE_LAB_TESTS, permissions.MANAGE_STOCK],
      UPDATE: [permissions.MANAGE_DEVICES, permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES,
      permissions.MANAGE_OVERVIEW, permissions.MANAGE_MEDICINE, permissions.MANAGE_HARVEST,
      permissions.MANAGE_ABW, permissions.MANAGE_LAB_TESTS, permissions.MANAGE_STOCK]
    },
    featureAllowedUsers: FEATURES_ALL_USERS
  },
  units: {
    name: "units",
    // keyToValidateAccess: "USER_TYPES",
    // featureAllowedUsers: FEATURES_ALL_USERS,
    // USER_TYPES: userTypesWithAccess
    keyToValidateAccess: "PERMISSIONS",
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_DEVICES, permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES,
      permissions.MANAGE_OVERVIEW, permissions.MANAGE_MEDICINE, permissions.MANAGE_HARVEST,
      permissions.MANAGE_ABW, permissions.MANAGE_LAB_TESTS, permissions.MANAGE_STOCK],
      UPDATE: [permissions.MANAGE_DEVICES, permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES,
      permissions.MANAGE_OVERVIEW, permissions.MANAGE_MEDICINE, permissions.MANAGE_HARVEST,
      permissions.MANAGE_ABW, permissions.MANAGE_LAB_TESTS, permissions.MANAGE_STOCK]
    },
    featureAllowedUsers: FEATURES_ALL_USERS
  },
  userAccessManagement: {
    name: "userAccessManagement",
    keyToValidateAccess: "USER_TYPES",
    featureAllowedUsers: FEATURES_ALL_USERS,
    USER_TYPES: adminUserTypes
  },
  pondTab: {
    name: "pondTab",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES],
      UPDATE: [permissions.MANAGE_CULTURE],
      DELETE: [permissions.MANAGE_CULTURE]
    }
  },
  devices: {
    name: "devices",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES],
      UPDATE: [permissions.MANAGE_DEVICES],
      DELETE: [permissions.MANAGE_DEVICES]
    }
  },
  pondMotherSettings: {
    name: "pondMotherSettings",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_DEVICES,
        permissions.MANAGE_SCHEDULES
      ],
      UPDATE: [
        permissions.MANAGE_DEVICES,
        permissions.MANAGE_SCHEDULES,
        permissions.MANAGE_EDIT_SCHEDULES
      ]
    }
  },
  scheduleSettings: {
    name: "scheduleSettings",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_SCHEDULES],
      UPDATE: [permissions.MANAGE_SCHEDULES],
      DELETE: [permissions.MANAGE_CULTURE]
    }
  },
  gateway: {
    name: "gateway",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_DEVICES,
        permissions.MANAGE_SCHEDULES
      ],
      UPDATE: [permissions.MANAGE_DEVICES]
    }
  },
  shrimpTalkSettings: {
    name: "shrimpTalkSettings",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_DEVICES,
        permissions.MANAGE_SCHEDULES
      ],
      UPDATE: [
        permissions.MANAGE_DEVICES,
        permissions.MANAGE_SCHEDULES,
        permissions.MANAGE_EDIT_SCHEDULES
      ]
    }
  },
  pondGuardSettings: {
    name: "pondGuardSettings",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: FEATURES_ALL_USERS,
    PERMISSIONS: {
      VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES],
      UPDATE: [permissions.MANAGE_DEVICES]
    }
  },
  feedBlowerSettings: {
    name: "feedBlowerSettings",
    keyToValidateAccess: "PERMISSIONS",
    featureAllowedUsers: [
      "5e1897b5188b23258b34654d",
      "5e565a0cbc2c4dd8b5f6f041",
      "5f1926e917a2492077983958",
      "5e5cfb322c599d13d25b607e"
    ],
    PERMISSIONS: {
      VIEW: [],
      UPDATE: []
    }
  },
  locationManagement: {
    name: "locationManagement",
    featureAllowedUsers: FEATURES_ALL_USERS,
    keyToValidateAccess: "USER_TYPES",
    USER_TYPES: highPriorityUserTypes
  },
  aquasim: {
    name: "aquasim",
    featureAllowedUsers: FEATURES_ALL_USERS,
    keyToValidateAccess: "USER_TYPES",
    USER_TYPES: highPriorityUserTypes // userTypesWithAccess
  }
};
export default {
  mixins: [featureToggleMixin, redirectsMixin, filtersMixin],
  components: {
    userProfile,
    units,
    pondTab,
    pondMotherSettings,
    devices,
    scheduleSettings,
    pondGuardSettings,
    feedBlowerSettings,
    shrimpTalkSettings,
    userAccessManagement,
    locationManagement,
    gateway,
    aquasim
  },
  data: function () {
    return {
      componentName: "userProfile"
    };
  },
  computed: {
    ...mapGetters("user", {
      isAquasimAvailable: "isAquasimAvailable",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    computedComponentName: function () {
      return this.$route.query.tab;
    },
    components() {
      return {
        userProfile: {
          label: this.$t("Usrs_profile"),
          ...tabToPermission.userProfile
        },
        units: {
          label: this.$t("Comn_preference"),
          ...tabToPermission.units
        },
        userAccessManagement: {
          label: this.$t("Comn_users"),
          ...tabToPermission.userAccessManagement
        },
        pondTab: {
          label: this.$t("Comn_ponds"),
          ...tabToPermission.pondTab
        },
        devices: {
          label: this.$t("Comn_devices"),
          ...tabToPermission.devices
        },
        pondMotherSettings: {
          label: this.$t("Comn_pond_mother"),
          ...tabToPermission.pondMotherSettings
        },
        scheduleSettings: {
          label: this.$t("PM_schedule"),
          ...tabToPermission.scheduleSettings
        },
        gateway: {
          label: this.$t("Comn_gateway"),
          ...tabToPermission.gateway
        },
        shrimpTalkSettings: {
          label: this.$t("Comn_shrimptalk"),
          ...tabToPermission.shrimpTalkSettings
        },
        pondGuardSettings: {
          label: this.$t("Comn_pond_guard"),
          ...tabToPermission.pondGuardSettings
        },
        feedBlowerSettings: {
          label: this.$tc("Comn_feed_blower", 1),
          ...tabToPermission.feedBlowerSettings
        },
        locationManagement: {
          label: this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2)),
          ...tabToPermission.locationManagement
        },
        ...(this.isAquasimAvailable && {
          aquasim: {
            label: "Aquasim",
            ...tabToPermission.aquasim
          }
        })
      };
    },
    computedTabItems: function () {
      const filters = [
        this.$gblUAMFilterItemsUserCanView,
        this.ftgm__filterObjFeatures
      ];
      return filters.reduce((accComponents, filter) => {
        accComponents = filter(accComponents);
        return accComponents;
      }, this.components);
    },
    location() {
      return this.$store.getters["user/getCurrUserLocation"] || {};
    },
    doesUserHasLocation() {
      return this.location._id !== "";
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((component) => {
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/settings",
          query: {
            tab: getFirstTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType
            )
          }
        });
      }
    });
  },
  methods: {
    handleChangeTabTo: function (tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName }
      });
    }
  }
};
</script>

<style lang="scss">
.settings-tab-component {
  $default-font-size: 13px;
  // $font-color: #404243;
  .el-menu--horizontal.type-tab {
    display: flex;
    overflow: auto;
    margin-bottom: 0px;
    border-radius: 2px;
    > .el-menu-item {
      height: 35px;
      line-height: 35px;
    }
    &::-webkit-scrollbar {
      height: 5px;
    }
  }
  // .is-disabled {
  //   .el-input__inner,
  //   .el-range-input {
  //     color: $font-color;
  //   }
  // }
  .settings-tab {
    box-sizing: border-box;
    /* height: 100%; */
  }
  .el-input__inner {
    text-align: center;
  }
  .er-tabs {
    .el-tabs__item {
      text-align: center;
      // padding-left: 10px;
      // padding: 0px 0px 0px 0px;
      font-size: 13px;
    }
    .el-tabs__content {
      background-color: #fbfbfb;
      padding: 0px;
    }
  }
  .el-tabs__item {
    padding: 0px 16px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    position: relative;
  }
  .el-card__body {
    padding: 0px;
  }
}
</style>
